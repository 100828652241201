
import * as actionTypes from '../actions/avatar/avatarActionTypes';
import { updateObject } from '../utility';
const initialState = {
    authby: '',
    avatar: '',
    email: '',
    id: '',
    name: '',
    social_avatar: ''
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_AVATAR:
            return getAvatar(state, action);
        default:
            return state;
    }
}

const getAvatar = (state, action) => {
    const obj = action.avatar
    return updateObject(state, obj);
};

export default reducer;
