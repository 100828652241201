import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Avatar as ProPic } from 'antd';
import * as actions from '../../store/actions/index';
import { Popover } from 'antd';
import classes from './Avatar.module.css'

class Avatar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: null,
            avatar: null,
            clicked: false,
        };

    }

    handleClickChange = visible => {
        this.setState({
            clicked: visible,
        });
    };
    hide = () => {
        this.setState({
            clicked: false,
        });
    }

    render() {
        const logout = () => {
            this.props.logout();
        };
        const text = <span className="notranslate">{this.props.auth.user?.name}</span>;
        const content = (
            <div>
                <NavLink onClick={this.hide} className="dropdown-item" to="/page/profile"><i className="fas fa-user"></i> &nbsp; Profile</NavLink>
                <div className="dropdown-divider"></div>
                <NavLink onClick={this.hide} className="dropdown-item" to="/page/mychannel"><i className="fas fa-heart"></i> &nbsp; My Channel</NavLink>
                <div className="dropdown-divider"></div>
                <NavLink onClick={this.hide} className="dropdown-item" to="/page/buy-card"><i className="far fa-credit-card"></i> &nbsp; Gift card</NavLink>
                <div className="dropdown-divider"></div>
                <a onClick={this.hide} className="dropdown-item" href="https://play.google.com/store/apps/details?id=com.app.calltv"><i className="far fa-comments"></i> &nbsp; Chat support</a>
                <div className="dropdown-divider"></div>
                <NavLink onClick={this.hide} className="dropdown-item" to="/page/transaction"><i className="fas fa-fw fa-file-invoice-dollar"></i> &nbsp; My Transaction</NavLink>
                <div className="dropdown-divider"></div>
                <div className="dropdown-item" onClick={logout} ><i className="fas fa-fw fa-sign-out-alt"></i> &nbsp; Logout</div>
            </div>
        );

        return (
            <div>
                {this.props.auth.success ? (
                    <Popover
                        visible={this.state.clicked}
                        onVisibleChange={this.handleClickChange}
                        placement="bottomRight"
                        title={text}
                        content={content}
                        trigger="click">
                        <ProPic className={classes.avatar} src={this.props.avatar?.avatar} />
                    </Popover>
                ) : ('')
                }
            </div>


        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(actions.logout())
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        avatar: state.avatar
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Avatar);
