import React, { Component } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classes from './Spinner.module.css';

class Spinner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            size: this.props.size
        }
    }

    render() {
        const antIcon = <LoadingOutlined style={{ fontSize: this.state.size }} spin />;

        return (
            <div className={classes.spinner}>
                <Spin indicator={antIcon} />
            </div>
        )
    }

}
export default Spinner;