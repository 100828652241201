import React from 'react'

const BarButton = () => {
    return (
        <button className="btn btn-link btn-sm text-secondary order-1 order-sm-0" id="sidebarToggle">
            <i className="fas fa-bars"></i>
        </button>
    )
}

export default BarButton
