import axios from 'axios';
import * as actionTypes from './dialActionsTypes';
const { REACT_APP_API_URL } = process.env;

export const dialSuccess = (dialNumber) => {
    return {
        type: actionTypes.DIAL_SUCCESS,
        dialNumber: dialNumber,
    };
};

export const play = (video) => {
    return {
        type: actionTypes.PLAY,
        video: video,
    };
};

export const DialNumber = (dialNumber) => {
    return (dispatch) => {
        axios.get(`${REACT_APP_API_URL}video/dial/${dialNumber}`)
            .then((res) => {
                const video = res.data.data;
                dispatch(play(video));
            })
            .catch((err) => {
                console.log(err);
            });
    }
};
