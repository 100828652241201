import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player'
import classes from './Player.module.css';
class Player extends Component {
    constructor(props) {
        super(props);
        this.state = {
            video: null,
            isDialing: false,
            dialNumber: null
        };


    }
    handlePlayPause = () => {
        console.log('CALLED');
        this.setState({ playing: !this.state.playing })
    }
    render() {

        return (
            <div className="col-md-8">
                <div className={["single-video", classes.playerBox].join(' ')}>
                    <ReactPlayer
                        controls
                        playing={true}
                        width='100%'
                        url={this.props.video?.url}
                        config={{
                            youtube: {
                                playerVars: {
                                    controls: 1,
                                    showinfo: 0,
                                    rel: 0,
                                    showsearch: 0,
                                    iv_load_policy: 3
                                }
                            }
                        }} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        video: state.dial.video,
    };
};

export default connect(mapStateToProps)(Player);