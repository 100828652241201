import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import dialReducer from './store/reducer/dialReducer'
import authReducer from './store/reducer/authReducer';
import popupReducer from './store/reducer/popupReducer';
import avatarReducer from './store/reducer/avatarReducer';
import submitReducer from './store/reducer/submitReducer';
const rootReducer = combineReducers({
  dial: dialReducer,
  auth: authReducer,
  popup: popupReducer,
  avatar: avatarReducer,
  submit: submitReducer,
})
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
  ,
  document.getElementById('root')
);
reportWebVitals();
