import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Input, Select, Button, Modal } from 'antd';
import { updateObject } from '../../store/utility';
import api from '../../tools/api';
import Spinner from '../../components/Spinner/Spinner';
import Text from '../../components/Text/Text';
import { Nav, Sidebar, Footer } from '../../components/common'
class MyProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user,
            loading: false,
            updateUser: null,
            submitting: false
        }
    }
    componentDidMount() {
        this.setState({
            updateUser: {
                "id": this.state.user.id,
                "name": this.state.user.name,
                "email": this.state.user.email,
                "country": this.state.user.country
            }
        })

    }
    onChangeName = (value) => {
        const userObj = updateObject(this.state.updateUser, { name: value.target.value })
        this.setState({
            updateUser: userObj
        }, () => {
            console.log(this.state.updateUser);
        });
    }

    onChangeCountry = (value) => {
        const userObj = updateObject(this.state.updateUser, { country: value })
        this.setState({
            updateUser: userObj
        }, () => {
            console.log(this.state.updateUser);
        });
    }
    onUpdate = () => {
        this.setState({ submitting: true });
        console.log(this.state.video);
        api.post(`user/UpdateProfile`, this.state.updateUser)
            .then((res) => {
                Modal.info({
                    title: "Success",
                    content: (
                        <div>
                            Updated successfully!
                        </div>
                    )
                });
                window.location.href = '';
                this.handleCancel()
                this.setState({ submitting: false });
            }).catch(error => {
                console.log(error);
                this.setState({ submitting: false });
            })
    }
    render() {
        const { Option } = Select;
        return (
            <div>
                <Nav></Nav>
                <Sidebar></Sidebar>
                <div id="wrapper">
                    <div id="content-wrapper">
                        <div className="container-fluid pb-0">
                            <div className="video-block section-padding">
                                <Card title="Profile">
                                    <div className="d-flex flex-column">
                                        <Text text="ID: "></Text>
                                        <Input
                                            className="mb-2"
                                            size={'large'}
                                            value={this.state.user.id}
                                            disabled={true}
                                        />
                                        <Text text="Email: "></Text>
                                        <Input
                                            className="mb-2"
                                            size={'large'}
                                            value={this.state.user.email}
                                            disabled={true}
                                        />
                                        <Text text="Name: "></Text>
                                        <Input
                                            className="mb-2"
                                            size={'large'}
                                            defaultValue={this.state.user.name}
                                            onChange={this.onChangeName}
                                        />
                                        <Text text="Nation:"></Text>
                                        <Select
                                            className="mb-2"
                                            style={{ width: '100%' }}
                                            placeholder="Select nation"
                                            size={'large'}
                                            value={this.state.user.country}
                                            onChange={this.onChangeCountry}
                                        >
                                            <Option value='Korea'>Korea</Option>
                                            <Option value='Japan'>Japan</Option>
                                            <Option value='Chain'>Chain</Option>
                                            <Option value='Other'>Other</Option>
                                        </Select>
                                        <Button onClick={this.onUpdate} loading={this.state.submitting} type="primary">Update</Button>
                                    </div>
                                </Card>
                                {this.state.loading && (<Spinner size={50}></Spinner>)}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    console.log(state.auth.user);
    return {
        user: state.auth.user
    };
};
export default connect(mapStateToProps)(MyProfile);