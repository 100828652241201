import React, { Component } from 'react';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

class SearchButton extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    renderSpeech = () => {
        window.location.href = `/page/search/`;
    }

    render() {


        return (
            <div id='search-button'>
                <Button onClick={this.renderSpeech} icon={<SearchOutlined />} />
            </div>
        );
    }
}

export default SearchButton;