import * as actionTypes from '../actions/dial/dialActionsTypes';
import { updateObject } from '../utility';
const initialState = {
    video:null,
    dialing: false,
    dialFailed:false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DIAL_SUCCESS:
            return videoPlay(state, action);
        case actionTypes.PLAY:
            return videoPlay(state, action);
        default:
            return state;
    }
}

const videoPlay = (state, action) => {
    const videoObj = {
        video:action.video,
        dialing: false,
        dialFailed:false
    }
    return updateObject(state, videoObj);
};

export default reducer;
