import React, { Component } from 'react';
import { Select, Input } from 'antd';
import classes from './PhoneNumberComponent.module.css';
import { countries } from 'country-data';
import InfoButton from '../../../Buttons/InfoButton/InfoButton';
import Text from '../../../Text/Text';

class PhoneNumberComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryName: props.country,
            countryCode: props.country_code,
            dialCode: props.dial_code,
            phoneNumber: props.phone
        }
    }
    render() {
        const { Option } = Select;
        const onChangeCountry = (value) => {
            const info = value.split('*');
            this.setState({
                countryName: info[0],
                countryCode: info[1],
                dialCode: info[2]
            }, () => {
                this.props.callback(this.state);
            })

        }
        const onChangePhoneNumber = (value) => {
            this.setState({
                phoneNumber: value.target.value
            }, () => {
                this.props.callback(this.state);
            })
        }

        return (
            <div className={['d-flex flex-column mb-2']}>
                <div className={['d-flex justify-content-between mb-2 align-self-stretch', classes.container].join(' ')}>
                    <div className="d-flex flex-column clearfix">
                        <Text text="Country:"></Text>
                        <Select
                            style={{ width: '100%', marginRight: '5px' }}
                            showSearch
                            optionFilterProp="children"
                            size="large"
                            onChange={onChangeCountry}
                            value={`${this.state?.countryName}*${this.state?.countryCode}*${this.state?.dialCode}`}
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {countries.all.map(country => (
                                <Option key={country?.alpha2 + country?.name} value={`${country?.name}*${country?.alpha2}*${country?.countryCallingCodes[0]}`}>{country?.name}</Option>
                            ))}
                        </Select>
                    </div>
                    <div className="d-flex flex-column clearfix">
                        <Text text="Phone:"></Text>
                        <Input
                            type="number"
                            pattern="^-?[0-9]\d*\.?\d*$"
                            onChange={onChangePhoneNumber}
                            prefix={this.state.dialCode}
                            style={{ width: '100%', marginLeft: '5px' }}
                            size="large"
                            defaultValue={this.state.phoneNumber}
                            suffix={
                                <InfoButton title="Phone number" content="<p style='text-align:justify;'>
                                Please write down your mobile phone number
                                </p>" />
                            }
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />
                    </div>
                </div>
            </div>

        );
    }
}

export default PhoneNumberComponent;