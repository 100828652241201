import React, { Component } from 'react';
import { Drawer, Input } from 'antd';
import api from '../../../../tools/api';
import { List, Avatar } from 'antd';
import classes from './SearchButton.module.css';
import { NavLink } from 'react-router-dom';
import { GetYoutubeThumb } from '../../../../Services/YoutubeService';
const { Search } = Input;
class SearchButton extends Component {
    state = {
        visible: false,
        placement: 'right',
        videos: [],
        from: 0,
        limit: 4000,
        cat: null,
        scat: null,
    };

    getVideos = (key) => {
        this.setState({
            videos: []
        })
        let URL = `video/SponsorVideoSearch/${this.props.page}/0/4000/null/null/${key}`;
        api.get(URL)
            .then((res) => {
                const newVideos = res.data.data
                let videoList = this.state.videos
                newVideos.forEach(video => {
                    videoList.push(video);
                });

                this.setState({
                    videos: videoList
                })
                console.log(this.state.videos);
                const from = this.state.from + 1;
                const limit = this.state.limit;
                this.setState({
                    from: from + limit
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    onChange = e => {
        this.setState({
            placement: e.target.value,
        });
    };
    render() {
        const { placement, visible } = this.state;
        const onSearch = value => {
            this.getVideos(value);
            console.log(value);
        };
        return (
            <div>
                <div onClick={this.showDrawer} data-activates="slide-out-right" className={classes.sidebarSearch}>
                    <i onClick={this.showDrawer} className="fa fa-search"></i>
                </div>
                <Drawer
                    placement={placement}
                    closable={false}
                    onClose={this.onClose}
                    visible={visible}
                    key={placement}
                >
                    <Search placeholder="Search text" size="large" onSearch={onSearch} className={[classes.searchInput, 'searchDrawer'].join(" ")} />
                    {/* {this.state.videos.length > 0 ? (
                        <Row gutter={[16, 24]}>
                            {this.state.videos.map((video) =>
                                <Col span={24}>
                                    <VideoCard key={video.id + Math.random()} video={video}></VideoCard>
                                </Col>
                            )}
                        </Row>
                    ) : null

                    

                    } */}
                    <List
                        itemLayout="horizontal"
                        dataSource={this.state.videos}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Avatar shape="square" size="large" src={GetYoutubeThumb(item.url)} />}
                                    title={
                                        <NavLink to={"/play/" + item.dial_number} >
                                            <div className={classes.gutterRow}>
                                                <div >{item.title}</div>
                                            </div>
                                        </NavLink>
                                    }
                                // description={item.dial_number}
                                />
                            </List.Item>
                        )}
                    />

                </Drawer>
            </div>
        );
    }
}

<NavLink to={"/"} className={classes.sidebar} >
    <i className="fa fa-arrow-left"></i>
</NavLink>

export default SearchButton;