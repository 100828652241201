import React from 'react';


const Translate = (props) => {

    return (

        <div id="google_translate_element"></div>
    )
}

export default Translate