import React, { Component } from 'react';
import classes from './VideoCard.module.css';
import thumb from '../../../../assets/mplayer/images/post3.jpg';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'antd';
import { GetYoutubeThumb } from '../../../../Services/YoutubeService';
class VideoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            video: props.video,
            thumbnail: thumb,
        };
    }
    componentDidMount() {
        this.getThumbnail();
    }
    async getThumbnail() {
        const video = this.state.video;
        if (video.type === "video/youtube" || video.type === "video/youtube_paylist" || video.type === "video/youtube_live") {
            try {
                const thumb = GetYoutubeThumb(video.url);
                this.setState({ thumbnail: thumb })
            } catch (err) {
                this.setState({ thumbnail: thumb })
            }

        } else {
            this.setState({ thumbnail: thumb })
        }
    }
    render() {

        return (
            <div>
                <div className={classes.content}>
                    <Row justify="space-around" align="middle" gutter={16}>
                        <Col className="gutter-row" span={12}>
                            {/* {this.state.video?.totalVideo} */}
                            <a href={"https://calltv.kr/play/" + this.state.video?.dial_number} className={classes.image} >
                                <img className={classes.img} src={this.state.thumbnail} alt="" />
                                {/* <a href={"https://calltv.kr/play/" + this.state.video?.dial_number} className={classes.time} >
                                    {this.state.video.dial_number}
                                </a> */}
                            </a>
                        </Col>
                        <Col span={12}>
                            <NavLink to={"/play/" + this.state.video.dial_number} >
                                <div className={classes.gutterRow}>
                                    <h5 className={[classes.title, "notranslate"].join(" ")}>{this.state.video.title}</h5>
                                </div>
                            </NavLink>
                            <p>
                                <a href={"https://calltv.kr/play/" + this.state.video?.dial_number}>
                                    Dial: {this.state.video?.dial_number}
                                </a>
                            </p>
                            <div>
                                <Row justify="start" align="middle">
                                    <Col span={24}>
                                        <p>
                                            {this.state.video?.user_name}
                                        </p>
                                    </Col>

                                    <Col span={12}>{this.state.video?.category ? (
                                        <div>
                                            <NavLink to={`/sponsor/${this.props.page}/${this.state.video?.category}`}>
                                                <div className={classes.catSponsor}>{this.state.video?.category}</div>
                                            </NavLink>
                                        </div>
                                    ) : null}
                                    </Col>
                                    <Col span={12}>{this.state.video?.sponsorCategory ? (
                                        <NavLink to={`/sponsor/${this.props.page}/${this.state.video?.category}/${this.state.video?.sponsorCategory}`}>
                                            <div className={classes.catSponsor}>{this.state.video?.sponsorCategory}</div>
                                        </NavLink>
                                    ) : null}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default VideoCard;