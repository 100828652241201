import React, { Component } from 'react'
import { connect } from 'react-redux';
import api from '../../tools/api';
import Title from '../../components/Title/Title';
import { Table } from 'antd';
import Spinner from '../../components/Spinner/Spinner';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { Nav, Sidebar, } from '../../components/common';
class TransactionPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: this.props.user,
            trn: [],
            from: 0,
            limit: 4000,
            loading: false
        }

    }
    componentDidMount() {
        this.GetTrn();
    }

    GetTrn = () => {
        this.setState({
            loading: true
        });
        api.get(`user/GetTransaction/${this.state.user?.id}/${this.state.from}/${this.state.limit}`)
            .then((res) => {
                const dataList = res.data.data
                let trnList = this.state.trn
                dataList.forEach(item => {
                    item.key = item.id
                    trnList.push(item);
                });

                this.setState({
                    trn: trnList
                }, () => {
                    // console.log(this.state.trn);
                })
                const from = this.state.from + 1;
                const limit = this.state.limit;
                this.setState({
                    from: from + limit,
                    loading: false
                });

            })
            .catch((err) => {
                this.setState({
                    loading: false
                });
                console.log(err);
            });
    }
    render() {

        const columns = [
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'id',
                width: "100px",
                render: (text, record) => (
                    <div style={{ width: '100px' }}>
                        {moment(record.date).format('DD-MM-YYYY')}
                    </div>
                )
            },
            {
                title: 'Gateway',
                dataIndex: 'gateway',
                key: "id"
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'id'
            },
            {
                title: 'Currency',
                dataIndex: 'currency',
                key: 'id'
            },
            {
                title: 'Submit date',
                dataIndex: 'submit_date',
                key: 'id',
                width: "100px",
                render: (text, record) => (
                    <div style={{ width: '100px' }}>
                        {moment(record.submit_date).format('DD-MM-YYYY')}
                    </div>
                )
            },
            {
                title: 'Duration',
                dataIndex: 'package_name',
                key: 'id'
            },
            {
                title: 'Expired date',
                dataIndex: 'expire_date',
                key: 'id',
                width: "100px",
                render: (text, record) => (
                    <div style={{ width: '100px' }}>
                        {moment(record.expire_date).format('DD-MM-YYYY')}
                    </div>
                )
            },
            {
                title: 'Active',
                dataIndex: 'active',
                key: 'id'
            },
            {
                title: 'Dial',
                dataIndex: 'dial_number',
                key: 'dial_number',
                render: (text, record) => (
                    <NavLink to={"/play/" + record.dial_number}>
                        <div className="notranslate text-truncate">{record.dial_number}</div>
                    </NavLink>
                )
            },
            {
                title: 'Channel',
                dataIndex: 'video_title',
                key: 'id',
                render: (text, record) => (
                    <NavLink to={"/play/" + record.dial_number}>
                        <div className="notranslate text-truncate">{record.video_title}</div>
                    </NavLink>
                )
            }
        ];
        return (
            <div>
                <Nav></Nav>
                <Sidebar></Sidebar>
                <div id="wrapper">
                    <div id="content-wrapper">
                        <div className="container-fluid pb-0">
                            <div className="video-block section-padding">
                                <Title title={"My Transaction"} showLine={false}></Title>
                                {this.state.trn.length > 0 && (
                                    <div className="rows">
                                        <Table
                                            size={'middle'}
                                            bordered={true}
                                            showHeader={true}
                                            scroll={{ x: 1300 }}
                                            loading={this.state.loading}
                                            dataSource={this.state.trn}
                                            columns={columns} />
                                    </div>
                                )}

                                {this.state.loading && (
                                    <Spinner size={50}></Spinner>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
const mapStateToProps = (state) => {
    return { user: state.auth.user };
};
export default connect(mapStateToProps, null)(TransactionPage);