import axios from 'axios';

export const GetYoutubeVideoInfo = (url) => {
    const id = getVideoId(url);
    return new Promise((resolve, reject) => {
        axios.get(`https://www.googleapis.com/youtube/v3/videos?part=id%2C+snippet&id=${id}&key=AIzaSyC5xq9p4FfO_o94jgYbh-Kq0o_b3ffect8`)
            .then((res) => {
                // console.log(res.data);
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    })

}



export const GetYoutubeThumb = (url) => {
    var imgPath = `https://i.ytimg.com/vi/${getVideoId(url)}/mqdefault.jpg`;
    // if (imgPath.includes('mqdefault.jpg')) {
    //     imgPath = '';
    // }
    return imgPath;
}

const getVideoId = (url) => {
    try {
        url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        // eslint-disable-next-line no-useless-escape
        return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
    } catch (error) {
        return null;
    }
}

export const ytId = (url) => {
    try {
        url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        // eslint-disable-next-line no-useless-escape
        return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
    } catch (error) {
        return null;
    }
}
