import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel';
import '../../../assets/vendor/owl-carousel/owl.carousel.css';
import '../../../assets/vendor/owl-carousel/owl.theme.css';
import SponsorCard from '../../Cards/SponsorCard/SponsorCard';
import api from '../../../tools/api';
class SponsorSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sponsors: []
        };
        this.GetSponsor = this.GetSponsor.bind(this);
    }
    componentDidMount() {
        this.GetSponsor();
    }
    GetSponsor() {
        api.get(`card/GetSponsor`)
            .then((res) => {
                this.setState({
                    sponsors: res.data.data
                })
            })
            .catch((err) => {
                console.log(err);
            });
    }
    render() {
        const options = {
            margin: 5,
            responsiveClass: true,
            nav: true,
            dots: false,
            autoplay: true,
            smartSpeed: 500,
            loop: true,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                700: {
                    items: 3,
                },
                1000: {
                    items: 8,

                }
            },
        };
        return (
            <div className="top-category section-padding mb-4">
                {this.state.sponsors.length>0?(<div className="row">
                    <div className="col-md-12">
                        {this.state.sponsors.length > 0 ? (
                            <OwlCarousel className='owl-theme owl-carousel-category' {...options}>
                                {this.state.sponsors.map((sponsor) =>
                                    <SponsorCard key={sponsor.id} sponsor={sponsor} ></SponsorCard>
                                )}
                            </OwlCarousel>
                        ) : ('')}

                    </div>
                </div>):("")}
            </div>
        )
    }
}

export default SponsorSlider
