import React, { useState } from 'react';
import { Modal } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';

const InfoButton = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    console.log(props);
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <InfoCircleOutlined onClick={showModal} style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer', padding: '5px' }} />
            <Modal title={props.title} visible={isModalVisible} cancelButtonProps={{ style: { display: 'none' } }} onOk={handleOk} onCancel={handleCancel}>
                <div>{ReactHtmlParser(props.content)}</div>
            </Modal>
        </>
    );
};

export default InfoButton;

// import React, { Component } from 'react';
// import { InfoCircleOutlined } from '@ant-design/icons';
// import { Popover } from 'antd';
// import Parser from 'html-react-parser';
// import { Modal, Button } from 'antd';
// import { useState } from 'react';
// // class InfoButton extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {}
//     }
//     render() {
//         const content = (
//             <div>
//                 {Parser(this.props.content)}
//             </div>
//         );
//         const [isModalVisible, setIsModalVisible] = useState(false);

//         return (
//             <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
//         <p>Some contents...</p>
//         <p>Some contents...</p>
//         <p>Some contents...</p>
//       </Modal>
//             // <Popover content={content} title={this.props.title} trigger="click">
//             //     <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer', padding: '5px' }} />
//             // </Popover>

//         );
//     }
// }

// export default InfoButton;