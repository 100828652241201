import React from 'react'
import classes from './Text.module.css';
export const Text = (props) => {
    return (
        <div className={["d-flex flex-row align-items-baseline", classes.gap].join(" ")}>
            <div className={classes.text}>
                {props?.text}
            </div>
            {props.subtext && (
                <div className={classes.subtext}>{props?.subtext}</div>
            )}
        </div>

    )
}
export default Text;