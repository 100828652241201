import React, { Component } from 'react';
import { Button } from 'antd';

class LoadMoreButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            from: this.props.from,
        }
    }
    loadMore = () => {
        this.setState({
            from: this.state.from + this.props.limit,
        }, () => {
            this.props.loadMore(this.state.from, this.props.limit);
        })

    }

    render() {
        return (
            <Button size={'large'} onClick={this.loadMore} block={true} type="primary" loading={this.props.loading}>
                Load more...
            </Button>
        );
    }
}

export default LoadMoreButton;