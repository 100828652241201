import React, { Component } from 'react';
import { Button } from 'antd';
import { AudioOutlined } from '@ant-design/icons';

class VoiceSearchButton extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    renderSpeech = () => {


        let SpeechRecognition = window.webkitSpeechRecognition
        let recognition = new SpeechRecognition()
        var lang = document.querySelector('html').getAttribute('lang')
        if (lang === 'en') {
            recognition.lang = 'en-US'
        } else if (lang === 'ko') {
            recognition.lang = 'ko-KR'
        } else if (lang === 'ja') {
            recognition.lang = 'ja-JP'
        } else {
            recognition.lang = 'en-US'
        }


        recognition.interimResults = true;
        recognition.continuous = false;
        recognition.interimResults = false;
        recognition.maxAlternatives = 1;
        recognition.start()
        recognition.onresult = (event) => {
            //handle result in here
            let word = event.results[0][0].transcript
            window.location.href = `/page/search/${word}`;
            console.log(word);
        }

    }

    render() {


        return (
            <div>
                <Button onClick={this.renderSpeech} icon={<AudioOutlined />} />
            </div>
        );
    }
}

export default VoiceSearchButton;