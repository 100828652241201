import React from 'react';
import { Collapse } from 'antd';
const VideoAbout = props => {
    const { Panel } = Collapse;
    return (
        <div className="single-video-info-content box mb-3">
            <Collapse bordered={true}>
                <Panel header="About" key="1">
                    <p>{props.video?.YoutubeInfo?.description}</p>
                </Panel>
            </Collapse>
            {/* <h6>About :</h6>
            <p>{props.video?.YoutubeInfo.description}</p> */}
        </div>
    )
}
export default VideoAbout