import React, { Component } from 'react'
import VideoCardList from '../../Cards/VideoCardList/VideoCardList';
import Spinner from '../../Spinner/Spinner';
import api from '../../../tools/api';
import Title from '../../Title/Title';
import classes from './CategoryVideosList.module.css'
class CategoryVideosList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            video: this.props.video,
            videos: [],
            from: 0,
            limit: 100000,
            loading: false
        };
        this.getNewVideos = this.getNewVideos.bind(this);
    }
    componentDidMount() {
        this.getNewVideos();
    }
    getNewVideos() {
        this.setState({
            loading: true
        });
        api.get(`video/CategoryVideos/${this.state.video.category_id}/${this.state.from}/${this.state.limit}`)
            .then((res) => {
                const newVideos = res.data.data
                let videoList = this.state.videos
                newVideos.forEach(video => {
                    videoList.push(video);
                });

                this.setState({
                    videos: videoList
                })
                const from = this.state.from + 1;
                const limit = this.state.limit;
                this.setState({
                    from: from + limit,
                    loading: false
                });

            })
            .catch((err) => {
                this.setState({
                    loading: false
                });
                console.log(err);
            });
    }

    render() {
        return (
            <div>

                {this.state.videos.length > 0 ? (
                    <div className={classes.list}>
                        <Title title={"Related Videos"} showLine={false}></Title>
                        {this.state.videos.map((video) =>
                            <VideoCardList key={video.id} video={video}></VideoCardList>
                        )}
                    </div>

                ) : ("")}
                <div>
                    {this.state.loading ? (
                        <Spinner size={50}></Spinner>
                    ) : ('')}
                </div>
            </div>

        )
    }
}
export default CategoryVideosList

