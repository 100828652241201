import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Input, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import Text from '../../Text/Text';
import { updateObject } from '../../../store/utility';
import api from '../../../tools/api';

class EditButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user,
            video: this.props.video,
            loading: false,
            visible: false,
            cat: this.props.video.cat,
            scat: this.props.video.scat,
            submitting: false
        }
    }


    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false, visible: false });
        }, 3000);
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    selectedPhone(value) {
        const phone = {
            country: value.countryName,
            country_code: value.countryCode,
            dial_code: value.dialCode,
            phone: value.phoneNumber
        }
        const videoObj = updateObject(this.state.video, phone)
        this.setState({
            video: videoObj
        }, () => {
            console.log(this.state.video);
        });

        this.setState({
            country: value.countryName,
            country_code: value.countryCode,
            dial_code: value.dialCode,
            phone: value.phoneNumber
        }, () => {
            // console.log(this.state);
        })
    }

    onChangeTitle = (value) => {
        const videoObj = updateObject(this.state.video, { title: value.target.value })
        this.setState({
            video: videoObj
        }, () => {
            // console.log(this.state.video);
        });
    }

    onChangeCategory = (value) => {
        const videoObj = updateObject(this.state.video, { category: value })
        this.setState({
            video: videoObj
        }, () => {
            // console.log(this.state.video);
        });
    }

    onChangeSCategory = (value) => {
        const videoObj = updateObject(this.state.video, { sponsorCategory: value })
        this.setState({
            video: videoObj
        }, () => {
            // console.log(this.state.video);
        });
    }

    onChangeType = (value) => {
        const videoObj = updateObject(this.state.video, { type: value })
        this.setState({
            video: videoObj
        }, () => {
            // console.log(this.state.video);
        });
    }

    onChangeURL = (value) => {
        const videoObj = updateObject(this.state.video, { url: value.target.value })
        this.setState({
            video: videoObj
        }, () => {
            // console.log(this.state.video);
        });
    }


    onChangeHomeURL = (value) => {
        const videoObj = updateObject(this.state.video, { home_url: value.target.value })
        this.setState({
            video: videoObj
        }, () => {
            // console.log(this.state.video);
        });
    }

    onChangeExtraText = (value) => {
        const videoObj = updateObject(this.state.video, { extra_text: value.target.value })
        this.setState({
            video: videoObj
        }, () => {
            // console.log(this.state.video);
        });
    }

    onChangeExtraTextLink = (value) => {
        const videoObj = updateObject(this.state.video, { extra_link: value.target.value })
        this.setState({
            video: videoObj
        }, () => {
            // console.log(this.state.video);
        });
    }

    onUpdate = () => {
        this.setState({ submitting: true });
        console.log(this.state.video);
        api.post(`video/add`, this.state.video)
            .then((res) => {
                Modal.info({
                    title: "Success",
                    content: (
                        <div>
                            Updated successfully!
                        </div>
                    )
                });
                window.location.href = '';
                this.handleCancel()
                this.setState({ submitting: false });
            }).catch(error => {
                console.log(error);
                this.setState({ submitting: false });
            })
    }
    render() {
        const { visible, submitting } = this.state;
        const { Option } = Select;
        return (
            <div>
                <Button type="text" shape="circle" onClick={this.showModal} icon={<EditOutlined />}></Button>

                <Modal
                    visible={visible}
                    title="Edit"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="submit" type="primary" loading={submitting} onClick={this.onUpdate}>Update</Button>,
                    ]}
                >
                    <div className="d-flex flex-column clearfix">
                        <Text text="Channel number:"></Text>
                        <Input
                            className="mb-2"
                            size={'large'}
                            value={this.state.video.dial_number}
                            disabled={true}
                        />
                        <Text text="Title:"></Text>
                        <Input
                            className="mb-2"
                            size={'large'}
                            value={this.state.video.title}
                            onChange={this.onChangeTitle}
                        />


                        {this.state.cat.length > 0 && (
                            <div>
                                <Text text="Category:"></Text>
                                <Select
                                    className="mb-2"
                                    style={{ width: '100%' }}
                                    placeholder="Select category"
                                    size={'large'}
                                    value={this.state.video.category}
                                    onChange={this.onChangeCategory}
                                >
                                    {this.state.cat.map((cat) =>
                                        <Option key={cat?.id} value={cat?.id}>{cat?.name}</Option>
                                    )}

                                </Select>
                            </div>
                        )}

                        {this.state.scat != null && (
                            <div>
                                <Text text="Sponsor category:"></Text>
                                <Select
                                    className="mb-2"
                                    style={{ width: '100%' }}
                                    placeholder="Select category"
                                    size={'large'}
                                    value={this.state.video.sponsorCategory}
                                    onChange={this.onChangeSCategory}
                                >
                                    {this.state.scat.map((scat) =>
                                        <Option key={scat?.id} value={scat?.id}>{scat?.name}</Option>
                                    )}

                                </Select>
                            </div>
                        )}

                        <Text text="URL:"></Text>
                        <Input
                            className="mb-2"
                            size={'large'}
                            onChange={this.onChangeURL}
                            defaultValue={this.state.video.url}
                        />



                        <Text text="Home URL"></Text>
                        <Input
                            className="mb-2"
                            size={'large'}
                            onChange={this.onChangeHomeURL}
                            defaultValue={this.state.video.home_url}
                        />
                        <Text text="Extra Text" subtext="Side button text(ex: buy, contact etc)"></Text>
                        <Input
                            className="mb-2"
                            size={'large'}
                            onChange={this.onChangeExtraText}
                            defaultValue={this.state.video.extra_text}
                        />
                        <Text text="Extra URL" subtext="Side button url"></Text>
                        <Input
                            className="mb-2"
                            size={'large'}
                            onChange={this.onChangeExtraTextLink}
                            defaultValue={this.state.video.extra_link}
                        />
                    </div>
                </Modal>
            </div>


        );
    }
}

const mapStateToProps = (state) => {
    // console.log(state.auth);
    return {
        user: state.auth.user
    };
};
export default connect(mapStateToProps)(EditButton);