import * as actionTypes from './actionTypes';
import axios from 'axios';
import { SetItem, RemoveItem, GetItem } from '../../../tools/LocalStorage';
import { showLoginPopup } from '../popups/PopupAction'
import { getAvatar } from '../avatar/avatarActions';
const { REACT_APP_API_URL } = process.env;


export const authStart = () => {
    return {
        type: actionTypes.AUTH_START,
    };
};

export const authSuccess = (authData) => {
    SetItem('user', authData);
    return {
        type: actionTypes.AUTH_SUCCESS,
        user: authData,
        success: true
    };
};

export const authFailed = (error) => {
    return {
        type: actionTypes.AUTH_FAILED,
        error: error
    };
};

export const authLogout = () => {
    RemoveItem("user");
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const logout = () => {
    return (dispatch) => {
        dispatch(authLogout());
    }
}


export const login = (email, password, action) => {
    return (dispatch) => {
        const authUser = {
            email: email,
            password: password
        };
        dispatch(authStart());
        axios
            .post(`${REACT_APP_API_URL}user/login`, authUser)
            .then((res) => {
                if (res.data?.status === 200) {
                    dispatch(authSuccess(res.data.data));
                    dispatch(showLoginPopup(false, action));
                    dispatch(getAvatar(res.data.data?.id));
                }
            })
            .catch((err) => {
                dispatch(authFailed(err.response));
            });
    };
};

export const loginWithGoogl = (authUser) => {
    return (dispatch) => {
        dispatch(authStart());
        axios
            .post(`${REACT_APP_API_URL}user/GmailLogin`, authUser)
            .then((res) => {
                if (res.data?.status === 200) {
                    dispatch(authSuccess(res.data.data));
                    dispatch(showLoginPopup(false));
                    dispatch(getAvatar(res.data.data?.id));
                }
            })
            .catch((err) => {
                dispatch(authFailed(err.response));
            });
    };
};

export const authInit = () => {
    return (dispatch) => {
        dispatch(authStart());
        const user = GetItem('user')
        if (!user) {

        }
        axios.get(`${REACT_APP_API_URL}user/AuthInit`, {
            headers: {
                authorization: user?.token
            }
        }).then((res) => {
            if (res.data?.status === 200) {
                dispatch(authSuccess(res.data.data));
                dispatch(showLoginPopup(false));
                dispatch(getAvatar(res.data.data?.id));
            }
        })
            .catch((err) => {
                dispatch(authFailed(err.response));
            });
    };
};
