import React, { Component } from 'react'
import api from '../../tools/api';
import NavbarComponent from './components/NavBar/NavbarComponent';
import VideoCard from './components/VideoCard/VideoCard';
import SponsorSlider from './components/Slider/SponsorSlider';
import classes from './SponsorVideosPage.module.css';
import { Row, Col, Alert } from 'antd';
import Footer from './components/Footer/Footer';
import CategorySelector from './components/CategorySelector/CategorySelector';
import SponsorCategorySelector from './components/SponsorCategorySelector/SponsorCategorySelector';
import LoadMoreButton from '../../components/Buttons/LoadMoreButton/LoadMoreButton';
class SponsorVideosPage extends Component {
    videoPerPage = 20;
    constructor(props) {
        super(props);
        this.state = {
            videos: [],
            from: 0,
            limit: 10,
            cat: null,
            scat: null,
            loading: false
        };

        // this.getVideos = this.getVideos.bind(this);
    }
    componentDidMount() {
        this.getVideos(this.state.from, this.state.limit);
    }
    selectCategory = (cat) => {
        this.setState({
            cat: cat
        }, () => {
            this.getVideos(this.state.from, this.state.limit, false);
        })
    }
    selectSponsorCategory = (scat) => {
        this.setState({
            scat: scat
        }, () => {
            this.getVideos(this.state.from, this.state.limit, false);
        })
    }

    getVideos = (from, limit, loadMore = true) => {

        let URL = `video/SponsorVideos/${this.props.match.params.id}/${from}/${limit}/`;

        if (this.state.cat == null && this.state.scat == null) {
            URL += `null/null`;
        }
        if (this.state.cat != null && this.state.scat == null) {
            URL += `${this.state.cat}/null`;
        }
        if (this.state.cat == null && this.state.scat != null) {
            URL += `null/${this.state.scat}`;
        }

        if (this.state.cat != null && this.state.scat != null) {
            URL += `${this.state.cat}/${this.state.scat}`;
        }

        console.log(URL);
        this.setState({
            loading: true
        })
        if (loadMore === false) {
            this.setState({
                videos: []
            })
        }
        api.get(URL)
            .then((res) => {
                const newVideos = res.data.data
                let videoList = this.state.videos
                newVideos.forEach(video => {
                    videoList.push(video);
                });

                this.setState({
                    videos: videoList,
                    loading: false
                })
            })
            .catch((err) => {
                console.log(err);
            });
    }

    onChangePage = (page) => {
        this.setState({
            videos: [],
            page: page,
            start: page - 1,
        }, () => {
            this.getVideos();
        })
    }

    render() {

        return (
            <div>
                <NavbarComponent backLink={``} title={this.state.videos[0]?.sponsor} page={this.props.match.params.id} cat={this.state.cat} scat={this.state.scat}></NavbarComponent>
                <div className={[classes.newVideo].join(" ")}>
                    <SponsorSlider page={this.props.match.params.id}></SponsorSlider>
                    <div className={[classes.container]}>

                        <Col span={24} className={classes.container}>
                            <Row className={classes.title} gutter={[10, 10]} justify="space-between" align="middle">
                                <Col span={12}>
                                    <CategorySelector selector={this.selectCategory} ></CategorySelector>

                                </Col>
                                <Col span={12}>
                                    <SponsorCategorySelector selector={this.selectSponsorCategory} page={this.props.match.params.id}></SponsorCategorySelector>
                                </Col>
                            </Row>
                            {this.state.videos.length > 0 ? (
                                <div >
                                    {
                                        this.state.videos.length > 0 ? (
                                            <Row gutter={[16, 24]}>
                                                {
                                                    this.state.videos.map((video) =>
                                                        <Col key={video.id + Math.random()} span={24}>
                                                            <VideoCard linked='true' page={this.props.match.params.id} video={video}></VideoCard>
                                                        </Col>
                                                    )
                                                }
                                            </Row>
                                        ) : null
                                    }
                                </div>

                            ) :
                                <div>
                                    <Row justify="center" align="middle" className={classes.container}>
                                        <Alert message="Video not found in this category" type="warning" />
                                    </Row>
                                </div>
                            }
                            <br></br>
                            <Row justify="center">
                                <LoadMoreButton
                                    loadMore={this.getVideos.bind(this)}
                                    from={this.state.from}
                                    limit={this.state.limit}
                                />
                            </Row>
                        </Col>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    }
}
export default SponsorVideosPage
