import React from 'react'
import { Divider } from 'antd';

const Title = props => {
    return (

        <Divider orientation="left">{props.title}</Divider>

        // <div className="col-md-12s">
        //     {props.showLine?(<hr></hr>):('')}
        //     {/* <div className="main-title">
        //         <div className="btn-group float-right right-action">
        //             <div  className="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        //                 <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
        //             </div>
        //             <div className="dropdown-menu dropdown-menu-right">
        //                 <div className="dropdown-item" ><i className="fas fa-fw fa-star"></i> &nbsp; Top Rated</div>
        //                 <div className="dropdown-item" ><i className="fas fa-fw fa-signal"></i> &nbsp; Viewed</div>
        //                 <div className="dropdown-item" ><i className="fas fa-fw fa-times-circle"></i> &nbsp; Close</div>
        //             </div>
        //         </div>

        //     </div> */}
        //     <h6>{props.title}</h6>
        // </div>
    )
}

export default Title
