import React from 'react';
import { NavLink } from 'react-router-dom';
import Translate from '../Translate/Translate';
import classes from './Sidebar.module.css';
const Sidebar = () => {
    return (
        <ul className="sidebar navbar-nav">
            <li className="nav-item active">
                <NavLink to="/" className="nav-link">
                    <i className="fas fa-fw fa-home"></i>
                    <span>Home</span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/page/categories" className="nav-link">
                    <i className="far fa-list-alt"></i>
                    <span>Categories</span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/page/promo" className="nav-link">
                    <i className="fas fa-fw fa-star"></i>
                    <span>Promo</span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/page/community" className="nav-link">
                    <i className="fas fa-fw fa-user"></i>
                    <span>Community</span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/page/distributors" className="nav-link">
                    <i className="fas fa-fw fa-link"></i>
                    <span>Distributors</span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/page/live" className="nav-link">
                    <i className="fas fa-fw fa-broadcast-tower"></i>
                    <span>Live</span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/page/playlist-videos" className="nav-link">
                    <i className="fas fa-fw fa-film"></i>
                    <span>Playlist</span>
                </NavLink>
            </li>
            <div className={classes.language}>
                <Translate></Translate>
            </div>


            <li className="nav-item channel-sidebar-list">
                <h6>SERVICE</h6>
            </li>
            <li className="nav-item">
                <a href="https://calltv.kr" className="nav-link">
                    <i className="fas fa-tv"></i>
                    <span>CallTV</span>
                </a>
            </li>
            <li className="nav-item">
                <a href="http://calltvpartners.com" className="nav-link">
                    <i className="fas fa-user-friends"></i>
                    <span>Partners</span>
                </a>
            </li>
            <li className="nav-item">
                <a href="http://videocampus.cafe24.com" className="nav-link">
                    <i className="fas fa-video"></i>
                    <span>Video campus</span>
                </a>
            </li>

            <li className="nav-item mb-5">
                <a href="http://prtv.kr/콜tv-웨비나/)" className="nav-link">
                    <i className="fas fa-fw fa-briefcase"></i>
                    <span>Webinar</span>
                </a>
            </li>

        </ul>
    )
}

export default Sidebar
