import React, { Component } from 'react';
import { PayPalButton as PayPalBtn } from "react-paypal-button-v2";
import { Select } from 'antd';
import api from '../../../tools/api';
class PayPalButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            packages: [],
            package: null,
            trans_id: "",
            amount: "",
            duration: ""
        }
    }

    componentDidMount() {
        this.GetPackage();
    }

    GetPackage() {
        api.get(`card/GetPackage`)
            .then((res) => {
                this.setState({
                    packages: res.data.data
                })
            }, (err) => { })
    }
    render() {
        const { REACT_APP_PAYPAL_CLIENT_ID } = process.env;
        const { Option } = Select;
        const onChangePackage = (value) => {
            const { packages } = this.state;
            const pkg = packages.find(item => item.id === value);
            this.setState({
                package: pkg
            });
        }
        return (
            <div className="d-flex flex-column clearfix">
                {this.state.packages.length > 0 && (
                    <Select className="mb-2"
                        style={{ width: '100%' }}
                        size={'large'}
                        value={this.state.type}
                        onChange={onChangePackage}
                    >
                        {this.state.packages.map((pkg) =>
                            <Option key={pkg?.id} value={pkg.id}>{`${pkg?.name} Price: $${pkg?.price} USD`}</Option>
                        )}
                    </Select>
                )}
                {this.state.package && (
                    <PayPalBtn
                        shippingPreference="NO_SHIPPING"
                        amount={this.state.package?.price}
                        onSuccess={(details, data) => {
                            const payment = {
                                trans_id: data.orderID,
                                status: details.status,
                                gateway: 'PayPal',
                                amount: this.state.package?.price,
                                currency:'usd',
                                duration: this.state.package?.duration,
                                packageId:this.state.package?.id,
                                remarks: 'Submit with paypal'
                              };
                            this.props.callback(payment);
                            // console.log(details);
                        }}
                        options={{
                            clientId: REACT_APP_PAYPAL_CLIENT_ID
                        }}
                    />
                )}

            </div>

        );
    }
}

export default PayPalButton;