import React, { Component } from 'react';
import { Form, Input, Modal } from 'antd';
import api from '../../../../tools/api';
import v1 from '../../../../assets/img/v1.png';
import classes from './CheckChannel.module.css';
import InfoButton from '../../../Buttons/InfoButton/InfoButton';

class CheckChannel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            video: null,
            thumbnail: v1,
            loading: false,
            channelNumber: props.channelNumber
        }
    }

    onSearch = (value) => {
        const count = value.length;
        if (count <= 7) {
            Modal.error({
                title: `Error`,
                content: (
                    <div>
                        <p class="text-left">
                            Minimum 8 digit needed
                        </p>
                    </div>
                ),
                onOk() { },
            });
            return;
        }
        this.setState({ loading: true })
        api.get(`video/dial/${value}`)
            .then((res) => {
                this.setState({ loading: false, channelNumber: value })
                if (res.data) {
                    this.info(res.data.data);
                    const response = {
                        found: true,
                        channelNumber: value
                    }
                    this.props.callback(response);
                } else {
                    this.setState({ loading: false, channelNumber: value })
                    const response = {
                        found: false,
                        channelNumber: value
                    }
                    this.props.callback(response);
                }
            })
            .catch((err) => {
                this.setState({ loading: false, channelNumber: value })
                const response = {
                    found: false,
                    channelNumber: value
                }
                this.props.callback(response);
            })
    };

    info = (video) => {
        Modal.info({
            title: `Already in use`,
            content: (
                <div>
                    <p class="text-left">
                        Channel number {video?.dial_number} is used for {video?.title}. Please use different number.
                    </p>
                </div>
            ),
            onOk() { },
        });
    }


    render() {
        const { Search } = Input;



        return (
            <div className={['d-flex flex-column justify-content-center', classes.container].join(' ')}>
                <Form
                    name="basic"
                    initialValues={{
                        channelNumber: this.state.channelNumber
                    }}>
                    <Form.Item
                        name="channelNumber"
                        rules={[
                            {
                                required: true,
                                message: 'Minimum 8 digit needed',
                            },
                        ]}>
                        <Search
                            name="channelNumber"
                            type="number"
                            pattern="^-?[0-9]\d*\.?\d*$"
                            enterButton="Next"
                            size="large"
                            loading={this.state.loading}
                            onSearch={this.onSearch}
                            suffix={
                                <InfoButton title="Channel number" content="<p >
                                This is where you can make a unique channel number for Call TV where you can view your video.<br>
                                Regular call TV card buyers can only write down more than a number of 8 digits. And If possible, please write down a number that is easy to memorize (for e.g.) your phone number<br>
                                # Channel numbers less than 8 digits can be submitted on our website (http://calltvpartners.com)<br> <br>
                                <a href='http://calltvpartners.com'>inquiry channel number</a>
                                </p>" />
                            }
                            onKeyPress={(event) => {
                                try {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                } catch (error) {
                                }
                            }}
                        />
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default CheckChannel;