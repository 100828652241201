import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import s1 from '../../../assets/img/s1.png';
const { REACT_APP_CMS_URL } = process.env;
class DistributorCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            distributor: props.distributor,
            thumbnail: s1
        };
    }
    render() {
        return (
            <div className="item">
                <NavLink to={"/DistributorVideos/" + this.state.distributor?.id}>
                    <div className="category-item notranslate">
                        <img className="img-fluid" src={REACT_APP_CMS_URL + this.state.distributor?.image} alt={this.state.distributor?.name} />
                        <h6>{this.state.distributor?.name}</h6>
                    </div>
                </NavLink>
            </div>
        )
    }
}

export default DistributorCard
