import React, { Component } from 'react';
import { connect } from 'react-redux';
import Talk from "talkjs";
const { REACT_APP_TALKJS_ID, REACT_APP_CMS_URL } = process.env;
class ChatSupportPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user,
            loading: false
        }
        this.talkjsContainer = React.createRef();
    }
    componentDidMount() {
        Talk.ready.then(() => {
            var me = new Talk.User({
                id: this.state.user.id,
                name: this.state.user.name,
                email: this.state.user.email,
                photoUrl: REACT_APP_CMS_URL + this.state.user.avatar,
                welcomeMessage: "Hey there! How are you? :-)",
                role: "admin"
            });

            const talkSession = new Talk.Session({
                appId: REACT_APP_TALKJS_ID,
                me: me
            });



            var conversation = talkSession.getOrCreateConversation(Talk.oneOnOneId(me));
            conversation.setParticipant(me);


            var inbox = talkSession.createInbox({ selected: conversation });
            inbox.mount(this.talkjsContainer.current);
        });

    }

    render() {
        return (
            <div id="content-wrapper">
                <div className="container-fluid pb-0">
                    <div className="video-block section-padding">
                        <div ref={this.talkjsContainer} className="chatbox-container"></div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    };
};
export default connect(mapStateToProps)(ChatSupportPage);