import React, { Component } from 'react'
import { connect } from 'react-redux'
import Spinner from '../../components/Spinner/Spinner'
import api from '../../tools/api';
import ReactPageScroller from 'react-page-scroller';
import YouTube from 'react-youtube';
import classes from './ScrollVideo.module.css';
import { Row, Col } from 'antd';
import { Button, Modal, Input } from 'antd';
import { NavLink } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import {
    ArrowUpOutlined,
    ArrowDownOutlined,
    ShoppingCartOutlined,
    HomeOutlined,
    ShareAltOutlined,
    PhoneOutlined,
    ArrowLeftOutlined,
    SearchOutlined,
    PlusCircleOutlined,
    WechatOutlined,
    UserOutlined
} from '@ant-design/icons';
import SearchBox from '../../components/SearchBox/SearchBox';


export class ScrollVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            auth: null,
            items: [],
            from: 0,
            limit: 4000,
            loading: false,
            videos: [],
            currentPage: 0,
            player: null,
            showSearch: false,
        };
    }

    componentDidMount() {
        const videoId = this.props.match.params.id;
        if (videoId) {
            this.GetVerticalVideos(videoId);
        } else {
            this.GetVerticalVideos();
        }


    }
    handlePageChange = number => {
        // this.setState({ currentPage: number });
    };
    previous = () => {
        if (this.state.currentPage === 0) {
            return;
        }
        this.setState({
            currentPage: this.state.currentPage - 1
        })
    }
    next = () => {
        if (this.state.videos.length === this.state.currentPage + 1) {
            return;
        }
        this.setState({
            currentPage: this.state.currentPage + 1
        })

    }


    onReady = (event) => {


        // console.log(event.target);
        // event.target.playVideo();
    }
    play(player) {
        console.log(player);
    }

    showLoginPopup = () => {
        this.props.showLoginPopup(true);
    }


    render() {
        const opts = {
            height: window.innerHeight,
            width: '100%',
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 0,
            },
        };

        const { Search } = Input;

        const getVideoId = (url) => {
            try {
                url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
                // eslint-disable-next-line no-useless-escape
                return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
            } catch (error) {
                return null;
            }
        }



        const showModal = () => {
            this.setState({
                showSearch: true,
            })
        };

        const handleOk = () => {
            this.setState({
                showSearch: false,
            })
        };

        const handleCancel = () => {
            this.setState({
                showSearch: false,
            })
        };
        const onSearch = value => {
            if (value) {
                this.props.history.push(`/Search/${value}`);
            }

        }




        return (
            <div id="wrapper">
                <div id="content-wrapper">
                    {this.state.videos.length > 0 ? (
                        <div>
                            <ReactPageScroller
                                pageOnChange={this.handlePageChange}
                                onBeforePageScroll={this.handleBeforePageChange}
                                customPageNumber={this.state.currentPage}
                            >
                                {this.state.videos.map((video) =>
                                    <div key={video.id + Math.random()}>
                                        <div className={classes.mainDiv}>
                                            <div className={classes.videoDiv}>
                                                <YouTube videoId={getVideoId(video.url)} opts={opts} onReady={this.onReady} />
                                            </div>

                                            <div onClick={() => this.play(video.id)} className={classes.maskDiv}>
                                                <div className={classes.buttonBox}>
                                                    <Row justify="right" align=" middle" gutter={[10, 10]}>
                                                        {this.state.currentPage > 0 ? (
                                                            <Col span={24}>
                                                                <Button onClick={this.previous} size="large" shape="circle" ghost icon={<ArrowUpOutlined />} />
                                                                <div className={classes.navigationText} >Up</div>
                                                            </Col>
                                                        ) : null}

                                                        <Col span={24}>
                                                            <Button href={video.extra_link} size="large" shape="circle" ghost icon={<ShoppingCartOutlined />} />
                                                            <div className={classes.navigationText} >{video.extra_text}</div>
                                                        </Col>
                                                        <Col span={24}>
                                                            <Button onClick={this.previous} size="large" shape="circle" ghost icon={<HomeOutlined />} />
                                                            <div className={classes.navigationText} >Home</div>
                                                        </Col>
                                                        {/* <Col span={24}>
                                                            <Button onClick={this.previous} size="large" shape="circle" ghost icon={<ShareAltOutlined />} />
                                                        </Col> */}
                                                        <Col span={24}>
                                                            <Button href={"tel:" + video?.phone} size="large" shape="circle" ghost icon={<PhoneOutlined />} />
                                                            <div className={classes.navigationText} >Call</div>
                                                        </Col>
                                                        {this.state.currentPage === this.state.videos.length - 1 ? (
                                                            ""
                                                        ) : (
                                                            <Col span={24}>
                                                                <Button onClick={this.next} size="large" shape="circle" ghost icon={<ArrowDownOutlined />} />
                                                                <div className={classes.navigationText} >Down</div>
                                                            </Col>
                                                        )}


                                                    </Row>
                                                </div>

                                            </div>



                                        </div>
                                    </div>
                                )}
                            </ReactPageScroller>
                            <div className={classes.bottomMask}>
                                <Row justify="space-around" align="middle">
                                    <Col span={4.6}>
                                        <NavLink to="">
                                            <div >
                                                < ArrowLeftOutlined className={classes.buttons} />
                                            </div>
                                        </NavLink>

                                    </Col>
                                    <Col span={4.6}>
                                        <div onClick={showModal} className={classes.searchButton}>
                                            <SearchOutlined className={classes.buttons} />
                                        </div>
                                    </Col>
                                    <Col span={4.6}>
                                        <NavLink to="/page/submit">
                                            <PlusCircleOutlined className={classes.buttons} />
                                        </NavLink>
                                    </Col>
                                    <Col span={4.6}>
                                        <a href={"https://play.google.com/store/apps/details?id=com.app.calltv"}>
                                            <WechatOutlined className={classes.buttons} />
                                        </a>

                                    </Col>
                                    <Col span={4.6}>
                                        <NavLink to="/page/profile">
                                            <UserOutlined className={classes.buttons} />
                                        </NavLink>
                                    </Col>

                                </Row>
                            </div>
                        </div>
                    ) : ("")
                    }
                    {this.state.loading ? (<Spinner size={50}></Spinner>) : ("")}
                </div >

                <Modal title="Search" footer={null} mask={false} visible={this.state.showSearch} onOk={handleOk} onCancel={handleCancel}>
                    <div>
                        <Search placeholder="Input search text" size="large" onSearch={onSearch} style={{ width: "100%" }} />
                        {/* <SearchBox view={'pc'}></SearchBox> */}
                    </div>
                </Modal>
            </div >
        )
    }

    GetVerticalVideos(id = "") {
        this.setState({
            loading: true
        });
        api.get(`video/GetVerticalVideos/0/2000/${id}`)
            .then((res) => {
                const itemList = res.data.data
                let items = this.state.items
                itemList.forEach(item => {
                    items.push(item);
                });

                this.setState({
                    videos: items
                })
                const from = this.state.from + 1;
                const limit = this.state.limit;
                this.setState({
                    from: from + limit,
                    loading: false
                });
            })
            .catch((err) => {
                this.setState({
                    loading: false
                });
                console.log(err);
            });
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showLoginPopup: (show) => dispatch(actions.showLoginPopup(show, "SUBMIT")),
    };
};


const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        auth: state.auth,
        action: state.popup.action
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScrollVideo)