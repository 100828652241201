import React, { Component } from 'react';
import SponsorSlider from '../SponsorSlider/SponsorSlider';
import AgentSlider from '../AgentSlider/AgentSlider'
import DistributorSlider from '../DistributorSlider/DistributorSlider'
import CategorySlider from '../CategorySlider/CategorySlider';
import Title from '../../Title/Title';
import classes from './TopSlider.module.css';
class TopSlider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: 'SPONSORS',
            title: 'Promo',
            slide: <SponsorSlider></SponsorSlider>
        }

        this.show = this.show.bind(this);
    }

    show = (slide) => {
        switch (slide) {
            case 'SPONSORS':
                this.setState({
                    title: 'Promo',
                    slide: <SponsorSlider></SponsorSlider>
                })
                break;
            case 'CATEGORIES':
                this.setState({
                    title: 'Categories',
                    slide: <CategorySlider></CategorySlider>
                })
                break;
            case 'AGENTS':
                this.setState({
                    title: 'Community',
                    slide: <AgentSlider></AgentSlider>
                })
                break;
            case 'DISTRIBUTES':
                this.setState({
                    title: 'Distributors',
                    slide: <DistributorSlider></DistributorSlider>
                })
                break;

            default:
                this.setState({
                    title: 'Sponsors',
                    slide: <SponsorSlider></SponsorSlider>
                })
                break;
        }
    }

    render() {


        return (
            <div>
                <div className={["d-flex justify-content-between", classes.titleRow].join(" ")}>
                    <div>
                        <Title title={this.state.title}></Title>
                    </div>
                    <div className="btn-group float-right">
                        <div className="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                        </div>
                        <div className="dropdown-menu dropdown-menu-right">
                            <div onClick={(e) => {
                                this.show('SPONSORS')
                            }} className="dropdown-item">
                                <i className="fas fa-fw fa-star"></i>
                                &nbsp; Promo
                            </div>
                            <div onClick={(e) => {
                                this.show('CATEGORIES')
                            }} className="dropdown-item">
                                <i className="far fa-list-alt"></i>
                                &nbsp; Categories
                            </div>
                            <div onClick={(e) => {
                                this.show('AGENTS')
                            }} className="dropdown-item">
                                <i className="fas fa-fw fa-user"></i>
                                &nbsp; Community
                            </div>
                            <div onClick={(e) => {
                                this.show('DISTRIBUTES')
                            }} className="dropdown-item">
                                <i className="fas fa-fw fa-link"></i>
                                &nbsp; Distributors
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.slide}
            </div>
        );
    }
}
export default TopSlider;