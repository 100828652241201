import React, { Component } from 'react';
import { Button } from 'antd';
import { FacebookOutlined } from '@ant-design/icons';

class FacebookLoginButton extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div>
                <Button block={true} size="large" icon={<FacebookOutlined />}>Login with Facebook</Button>
            </div>
        );
    }
}

export default FacebookLoginButton;