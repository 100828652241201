import React, { Component } from 'react';
import { Card, Statistic, Input, } from 'antd';
import { Row, Col } from 'antd';
import { errorDialog, successDialog, paymentGatewayDialog, inputTextDialog } from '../../../Services/DialogService';
const { REACT_APP_IMP_CODE, REACT_APP_IMP_PG, REACT_APP_IMP_RETURN_URL } = process.env;
const { Search } = Input; class PrepaidCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user,
            package: this.props.package,
            receiver: null,
            qty: 1
        }
    }



    buyNow = async (value) => {
        const email = await inputTextDialog('Consignee', 'Email');
        if (email === null) {
            await errorDialog('Consignee', 'Please enter a receiver email');
            return;
        } else if (!email) {
            return;
        }

        this.setState({ receiver: email });

        const qty = Number(value);
        if (qty < 1) {
            await errorDialog('Send card', 'Minimum quantity required');
            return;
        }
        const pg = await paymentGatewayDialog();

        if (pg === null) {
            await errorDialog('Payment gateway', 'Please select a payment gateway');
            return;
        } else if (!pg) {
            return;
        }

        this.setState({ qty: qty });
        if (pg === 'kpg') {
            const amount = Number(qty) * this.state.package.price_won;
            this.payWithKpg(amount);
        }
    }

    payWithKpg = (amount) => {
        const merchant_uid = `mid_${new Date().getTime()}`
        this.setState({ loading: true });
        const payment = {
            trans_id: merchant_uid,
            amount: amount,
            currency: 'won',
            remarks: 'Payment with INICIS',
            paymentFor: 'buy_card',
            video: null,
            extend: null,
            buy_card: {
                user_id: this.state.user.id,
                user_name: this.state.user.name,
                sender: this.state.user.email,
                packageId: this.state.package?.id,
                package_name: this.state.package?.name,
                duration: this.state.package?.duration,
                qty: this.state.qty,
                receiver: this.state.receiver,
            }
        };

        const { IMP } = window;
        IMP.init(REACT_APP_IMP_CODE);
        const data = {
            pg: REACT_APP_IMP_PG,
            merchant_uid: merchant_uid,
            amount: amount,
            name: this.state.package.name,
            packageId: this.state.package.id,
            buyer_name: this.props.user.name,
            buyer_email: this.props.user.email,
            buyer_addr: this.props.user.country,
            m_redirect_url: REACT_APP_IMP_RETURN_URL,
            custom_data: payment
        };
        IMP.request_pay(data, this.callback);
    }

    callback = (response) => {
        this.setState({ loading: false });
        let { success, error_msg, } = response;
        // success = true;
        if (success) {
            successDialog('Payment', 'CallTV Card PIN Number was sent to your email successfully. it will take 2~3 min later to your email.');
        } else {
            errorDialog('Payment', 'Your payment not success! Error:' + error_msg);
        }
        console.log(response);
        // let payment;
    }



    render() {
        return (
            <div>
                <Card title={this.state.package.name} style={{ width: 250 }}>
                    <Row justify="space-around" align="middle">
                        <Col>
                            <Statistic title="USD" value={this.state.package.price} />
                        </Col>
                        <Col>
                            <Statistic title="￦" value={this.state.package.price_won} />
                        </Col>
                    </Row>
                    <br></br>
                    <Row justify="space-around" align="middle" gutter={[16, 16]}>
                        <Col>
                            <Search
                                type="number"
                                pattern="^-?[0-9]\d*\.?\d*$"
                                enterButton="Send"
                                defaultValue="1"
                                onSearch={this.buyNow}
                                prefix={'Qty:'}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} />
                        </Col>
                    </Row>


                </Card>

            </div>

        );
    }
}

export default PrepaidCard;