import React, { Component } from 'react';
import { Button, Image } from 'antd';
import { FileImageOutlined } from '@ant-design/icons';
import Text from '../../components/Text/Text';
import classes from './ImageSelector.module.css';
class ImageSelector extends Component {
    constructor(props) {
        super(props);
        this.inputReference = React.createRef();
        this.state = {
            image64: null,
            selectedFile: null,
        }
    }
    select = (event) => {
        this.setState(
            {
                selectedFile: event.target.files[0],
                image64: URL.createObjectURL(event.target.files[0])
            }, () => {
                this.props.callback(this.state.selectedFile);
            })
    }

    fileUploadAction = () => this.inputReference.current.click();
    render() {
        return (
            <div className={['d-flex flex-column justify-content-center', classes.selector].join(" ")}>
                <input hidden ref={this.inputReference} type="file" onChange={this.select} />
                {this.state.image64 && (
                    <div>
                        <Image
                            className="mb-2"
                            width={200}
                            src={this.state.image64}
                        />
                    </div>
                )}
                <Button className="mb-2" onClick={this.fileUploadAction} type="primary" icon={<FileImageOutlined />}>Select image</Button>
                <Text className="mb-2" text="Image size 500 X 90px" ></Text>
            </div>
        );
    }
}

export default ImageSelector;