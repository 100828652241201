import React, { Component } from 'react';
import { Button, Modal, Form, Input, Alert } from 'antd';
import api from '../../../tools/api';
import { MailOutlined } from '@ant-design/icons';
class ResetPasswordButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleModel: false,
            loading: false,
            successMsg: null,
            errorMsg: null,
            email: "skytouch@live.com"
        }
    }
    showModal = () => {
        this.setState({
            visibleModel: true
        })
    }
    hideModal = () => {
        this.setState({
            visibleModel: false
        })
    }
    onFinish = (values) => {
        const post = {
            email: values.email
        }
        this.setState({
            loading: true
        })
        api.post(`user/ForgotPassword`, post)
            .then((res) => {
                const { status } = res.data;
                if (status === 200) {
                    this.setState({
                        successMsg: "Password reset code has been sent to your email",
                        email: ""
                    })
                }
                this.setState({
                    loading: false
                })
            }, (err) => {
                this.setState({
                    loading: false,
                    errorMsg: "Email not found!"
                }, () => {
                    console.log(this.state);
                })
            })
    };

    render() {
        const { visibleModel, successMsg, errorMsg, loading } = this.state;
        return (
            <div>

                <Button block={true} size={"large"} >Reset password?</Button>
                <Modal
                    visible={visibleModel}
                    title="Forgot password?"
                    destroyOnClose={true}
                    onOk={this.onFinish}
                    footer={null}
                    onCancel={this.hideModal}>
                    <div>
                        <Form
                            name="normal_login"
                            initialValues={{
                                remember: true,
                                email: this.state.email
                            }}
                            onFinish={this.onFinish}>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Email!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<MailOutlined />}
                                    placeholder="Email"
                                    size="large"
                                />
                            </Form.Item>
                            <div className="mb-3">
                                {successMsg && (
                                    <Alert message={successMsg} type={"success"} showIcon />
                                )}
                                {errorMsg && (
                                    <Alert message={errorMsg} type={"error"} showIcon />
                                )}
                            </div>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    block="true"
                                    size="large"
                                    loading={loading}
                                >Submit</Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default ResetPasswordButton;