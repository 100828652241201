import React, { Component } from 'react';
import { Button, Modal, Radio, Input } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import Text from '../../Text/Text';
import api from '../../../tools/api';
import PayPalButton from '../../Buttons/PayPalButton/PayPalButton';
import KoreanPaymentButton from '../KoreanPaymentButton/KoreanPaymentButton';
const pgList = [
    { label: 'Gift card', value: 'card' },
    { label: 'Credit card', value: 'kpg' },
    { label: 'PayPal', value: 'paypal' },
];

class ExtendButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            video: this.props.video,
            user: this.props.user,
            visible: false,
            loading: false,
            payment_type: 'card',
            card_number: null,
            payment: 'card'
        }
    }

    render() {
        const { visible, loading, extendBy: value3 } = this.state;
        const onChangeCardNumber = (value) => {
            this.setState({
                card_number: value.target.value
            })
        }
        return (
            <div>
                <Button onClick={this.showModal} type="text" shape="circle" icon={<ClockCircleOutlined />}></Button>
                <Modal
                    visible={visible}
                    title="Extend time with"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                >
                    <div className='d-flex flex-column justify-content-center'>
                        <Radio.Group
                            options={pgList}
                            onChange={this.onChangePG}
                            value={value3}
                            optionType="button"
                        />

                        {this.state.payment_type === 'card' && (
                            <div className="d-flex flex-column clearfix">
                                <Text text="Card number"></Text>
                                <Input
                                    className="mb-2"
                                    size={'large'}
                                    onChange={onChangeCardNumber}
                                    defaultValue={this.state.card_number}
                                />
                                <Button onClick={this.submit} key="submit" type="primary" loading={loading} >Submit</Button>
                            </div>
                        )}

                        {this.state.payment_type === 'paypal' && (
                            <div className="d-flex flex-column clearfix">
                                <Text text="Package"></Text>
                                <PayPalButton callback={this.paypalPayment.bind(this)}></PayPalButton>
                            </div>

                        )}

                        {this.state.payment_type === 'kpg' && (
                            <KoreanPaymentButton paymentFor={'extend'} video={this.state.video}></KoreanPaymentButton>
                        )}
                    </div>
                </Modal>
            </div>



        );
    }

    submit = () => {
        if (this.state.payment_type === 'card') {
            this.ExtendByCard();
        } else if (this.state.payment_type === 'paypal') {
            this.ExtendByPayment();
        } else if (this.state.payment.gateway === 'INICIS') {
            this.ExtendByPayment();
        }

    }

    ExtendByCard = () => {
        this.setState({ loading: true });
        const payment = {
            card_number: this.state.card_number,
            video_id: this.state.video.id,
            user_id: this.state.user.id
        };
        api.post('video/ExtendByCard', payment)
            .then((res) => {
                this.setState({ loading: false });
                console.log(res.data);
                if (res.data.status === 200) {
                    Modal.success({
                        title: 'Success',
                        content: 'Time extended successfully',
                    });
                    window.location.href = '';
                } else {
                    Modal.error({
                        title: 'Error',
                        content: res.data.message,
                    });

                }
            })
            .catch((err) => {
                console.log(err);
                Modal.error({
                    title: 'Error',
                    content: 'Invalid card number',
                });
                this.setState({ loading: false });
            });
    }


    ExtendByPayment = () => {
        this.setState({ loading: true });
        const payment =
        {
            "gateway": this.state.payment.gateway,
            "video_id": this.state.video.id,
            "status": this.state.payment.status,
            "trans_id": this.state.payment.trans_id,
            "user_id": this.state.user.id,
            "amount": this.state.payment.amount,
            "currency": this.state.payment.currency,
            "duration": this.state.payment.duration,
            "packageId": this.state.payment.packageId,
            "remarks": `extended by` + this.state.payment.gateway
        };
        api.post('video/ExtendByPayment', payment)
            .then((res) => {
                this.setState({ loading: false });
                console.log(res.data);
                if (res.data.status === 200) {
                    Modal.success({
                        title: 'Success',
                        content: 'Time extended successfully',
                    });
                    window.location.href = '';
                } else {
                    Modal.error({
                        title: 'Error',
                        content: res.data.message,
                    });

                }
            })
            .catch((err) => {
                console.log(err);
                Modal.error({
                    title: 'Error',
                    content: 'Unable extend',
                });
                this.setState({ loading: false });
            });
    }

    onChangePG = e => {
        this.setState({
            payment_type: e.target.value,
        });
    };

    paypalPayment(payment) {
        if (payment.status === 'COMPLETED') {
            this.setState({
                payment: payment
            }, () => {
                this.submit();
            })
        }

    }

    KPGPayment(payment) {
        console.log(payment);
        if (payment.status === "COMPLETED") {
            this.setState({
                payment: payment
            }, () => {
                this.submit();
            })
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    handleCancel = () => {
        this.setState({ visible: false });
    };

}

export default ExtendButton;