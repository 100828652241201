import React, { Component } from 'react';
import { Carousel } from 'antd';
import api from '../../../../tools/api';
import classes from './SponsorSlider.module.css';
class SponsorSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slides: [],
        };

    }
    componentDidMount() {
        this.GetSponsorSlide();
    }

    GetSponsorSlide() {
        this.setState({
            loading: true
        });
        api.get(`card/GetSponsorSlide/${this.props.page}`)
            .then((res) => {
                const slides = res.data.data
                // console.log(slides);
                this.setState({
                    slides: slides,
                    loading: false
                });
            })
            .catch((err) => {
                this.setState({
                    loading: false
                });
                console.log(err);
            });
    }
    render() {

        return (
            <div>
                {
                    this.state.slides.length > 0 ? (
                        <div>
                            <Carousel autoplay>
                                {this.state.slides.map((slid) =>
                                    <a key={slid.id + Math.random()} href={slid.link}>
                                        <img className={classes.slid} src={slid.image} alt="" />
                                    </a>
                                )}
                            </Carousel>
                        </div>) : null
                }
            </div>


        );
    }
}

export default SponsorSlider;