import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { Button, Modal, } from 'antd';
import Submit from '../../../components/Submit/Submit';

class UploadVideoButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            auth: null,
            loading: false,
            visible: false,
            step: {
                current: 0
            },
            typeList: [],
            categoryList: [],
            bannerList: [],
            scrollTextList: [],
            country: "Korea, Republic Of",
            title: "",
            dial_number: '',
            country_code: 'KR',
            dial_code: '+82',
            phone: '',
            user_id: '7',
            type: '',
            url: "",
            payment_type: 'card',
            payment: '',
            card_number: '',
            category: "",
            bottom_banner_right: "",
            bottom_banner_right_url: "",
            errorMsg: "",
            isValidVideoInfo: false,
            submitting: false,
            screen_type: "full_screen_only",
            banner: "",
            scroll_text: "",
            scroll_news: "",
            home_url: "",
            extra_text: "",
            extra_link: "",
            product_card: "",
            submit_type: "submit_card"
        }

        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        const path = window.location.pathname;
        if (path === '/submit') {
            this.showModal();
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.success && nextProps.action === "SUBMIT") {
            this.setState({
                visible: true,
            }, () => {
                // this.getCategory();
                // this.getType();
                // this.getBanner();
                // this.getScroll();
            });
        }
    }
    showModal = () => {
        if (this.props.auth.success) {
            this.setState({
                visible: true,
            }, () => {

            });

        } else {
            this.props.showLoginPopup(true);
        }

    };

    authCallback = (auth) => {
        console.log(auth);
    }



    handleCancel = () => {
        this.setState({ visible: false });
    };

    stepOne(result) {
        if (!result.found) {
            this.setState({
                step: { current: 1 },
                dial_number: result.channelNumber
            })
        }
    }

    steVideoInfo(info) {
        const product = info.product;
        console.log(info);
        this.setState({
            title: product.name,
            category: product.category,
            type: product.video_type,
            url: product.url,
            isValidVideoInfo: true,
            card_number: info.number,
            home_url: product.home_url,
            extra_text: product.extra_text,
            extra_link: product.extra_link,
            banner: product.banner,
            scroll_news: product.scroll_news,
            scroll_text: product.scroll_text,
            submit_type: "product_card"
        })
    }

    cardSwitch = (e) => {
        this.setState({ submit_type: e.target.value })
    }

    selectedPhone(value) {
        this.setState({
            country: value.countryName,
            country_code: value.countryCode,
            dial_code: value.dialCode,
            phone: value.phoneNumber
        }, () => {
            // console.log(this.state);
        })
    }

    paypalPayment(payment) {
        if (payment.status === 'COMPLETED') {
            this.setState({
                payment: payment
            }, () => {
                this.onSubmit();
            })
        }

    }

    KPGPayment(payment) {
        if (payment.status === 'COMPLETED') {
            this.setState({
                payment: payment
            }, () => {
                this.onSubmit();
            })
        }
    }

    onSubmit = () => {
        if (!this.state.user_id) {
            Modal.error({
                title: "Error",
                content: (
                    <div>
                        User not found;
                    </div>
                )
            });
            return;
        }
        const submitData = {
            country: this.state.country,
            title: this.state.title,
            dial_number: this.state.dial_number,
            country_code: this.state.country_code,
            dial_code: this.state.dial_code,
            phone: this.state.phone,
            user_id: this.props.auth.user?.id,
            type: this.state.type,
            url: this.state.url,
            payment_type: this.state.payment_type,
            payment: this.state.payment,
            card_number: this.state.card_number,
            category: this.state.category,
            screen_type: this.state.screen_type,
            banner: this.state.banner,
            scroll_text: this.state.scroll_text,
            scroll_news: this.state.scroll_news,
            bottom_banner_right: this.state.bottom_banner_right,
            bottom_banner_right_url: this.state.bottom_banner_right_url,
            home_url: this.state.home_url,
            extra_text: this.state.extra_text,
            extra_link: this.state.extra_link
        };
        console.log(submitData)
        this.setState({
            submitting: true
        });

    }

    render() {
        const { visible } = this.state;

        // const checkVideoInfo = () => {
        //     if (
        //         this.state.title &&
        //         this.state.category &&
        //         this.state.type &&
        //         this.state.url) {
        //         this.setState({
        //             isValidVideoInfo: true
        //         })
        //     } else {
        //         this.setState({
        //             isValidVideoInfo: false
        //         })
        //     }
        // }



        return (
            <div>
                {/* <AuthPopup authCallback={this.authCallback.bind(this)} show={true} ></AuthPopup> */}
                <Button onClick={this.showModal} danger>Submit</Button>
                <Modal
                    visible={visible}
                    title="Submit new channel"
                    onOk={this.handleOk}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <div className="flex-column">

                        <Submit />
                    </div>
                </Modal>
            </div >
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showLoginPopup: (show) => dispatch(actions.showLoginPopup(show, "SUBMIT")),
    };
};


const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        auth: state.auth,
        action: state.popup.action
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadVideoButton);