import React, { Component } from 'react';
import { connect } from 'react-redux';
import PrepaidCard from '../../components/Cards/PrepaidCard/PrepaidCard';
import Title from '../../components/Title/Title';
import api from '../../tools/api';
import { Row, Col } from 'antd';
import Spinner from '../../components/Spinner/Spinner';
import { Nav, Sidebar, Footer } from '../../components/common';
class BuyCardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user,
            packages: [],
            package: null,
            loading: false
        }
    }
    componentDidMount() {
        this.GetPackage();
    }

    GetPackage = () => {
        this.setState({ loading: true });
        api.get(`card/GetPackage`)
            .then((res) => {
                this.setState({
                    packages: res.data.data,
                    loading: false
                })
            }, (err) => {
                this.setState({ loading: false });
            })

    }
    render() {
        return (
            <div>
                <Nav></Nav>
                <Sidebar></Sidebar>
                <div id="wrapper">
                    <div id="content-wrapper">
                        <div className="container-fluid pb-0">
                            <Title title={"Buy card number"} showLine={false}></Title>
                            {this.state.packages.length > 0 && (
                                <Row gutter={[16, 16]}>
                                    {this.state.packages.map((pkg) =>
                                        <Col key={Math.random()} xs={24} sm={4} md={6} lg={6} xl={8} >
                                            <PrepaidCard package={pkg} user={this.props.user}></PrepaidCard>
                                        </Col>
                                    )}
                                </Row>
                            )}

                            {this.state.loading && (
                                <Spinner size={50}></Spinner>
                            )}
                        </div>
                    </div >
                </div>
                <Footer></Footer>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    };
};
export default connect(mapStateToProps)(BuyCardPage);