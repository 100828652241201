import React, { Component } from 'react';
import Title from '../../../components/Title/Title';
import UserCard from '../../Cards/UserCard/UserCard';
import api from '../../../tools/api';
class PopularChannels extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            from: 0,
            limit: 12,
            loading: false
        };
        this.getNewVideos = this.getNewVideos.bind(this);
    }
    componentDidMount() {
        this.getNewVideos();
    }
    getNewVideos() {
        this.setState({
            loading: true
        });
        api.get(`user/users/${this.state.from}/${this.state.limit}`)
            .then((res) => {
                const newVideos = res.data.data
                let videoList = this.state.users
                newVideos.forEach(video => {
                    videoList.push(video);
                });

                this.setState({
                    videos: videoList
                })
                const from = this.state.from + 1;
                const limit = this.state.limit;
                this.setState({
                    from: from + limit,
                    loading: false
                });

            })
            .catch((err) => {
                this.setState({
                    loading: false
                });
                console.log(err);
            });
    }

    render() {
        return (
            <div>
                {this.state.users.length > 0 ? (
                    <div className="video-block section-padding">
                        <div className="row">
                            <Title title={"Users"} showLine={true}></Title>
                            {this.state.users.map((user) =>
                                <UserCard key={user.id} user={user}></UserCard>
                            )}
                        </div>
                        <div className="d-flex justify-content-center">
                            <button onClick={this.getNewVideos} type="button" className="btn btn-primary">Load more...</button>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                ) : ("")}
            </div>
        )
    }
}
export default PopularChannels