import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import { Divider } from 'antd';
import v1 from '../../../assets/img/v1.png';
import { GetYoutubeThumb } from '../../../Services/YoutubeService';
import MySubmitControl from '../MySubmitControl/MySubmitControl';
import classes from './VideoCard.module.css';
class VideoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            video: props.video,
            thumbnail: v1,
        };
    }
    componentDidMount() {
        this.getThumbnail();
    }
    async getThumbnail() {
        const video = this.state.video;
        if (video.type === "video/youtube" || video.type === "video/youtube_paylist" || video.type === "video/youtube_live" || video.type === "youtube/vertical") {
            try {
                const thumb = GetYoutubeThumb(video.url);
                this.setState({ thumbnail: thumb })
            } catch (err) {
                this.setState({ thumbnail: v1 })
            }

        } else {
            this.setState({ thumbnail: v1 })
        }
    }
    render() {
        return (
            <div className="col-xl-3 col-sm-6 mb-3">
                <div className="video-card">
                    <div className="video-card-image">
                        {this.state.video?.type === "web/link" || this.state.video?.type === "personal/link" ? (
                            // eslint-disable-next-line react/jsx-no-target-blank
                            <a href={this.state.video.url} target="_blank" className={["play-icon", classes.playButton].join(" ")} >
                                <i className="fas fa-play-circle"></i>
                            </a>
                        ) : (
                            <NavLink to={"/play/" + this.state.video.dial_number} className={["play-icon", classes.playButton].join(" ")} >
                                <i className="fas fa-play-circle"></i>
                            </NavLink>
                        )}

                        <div><img className={["img-fluid", classes.thumb].join(" ")} src={this.state.video.thumb} alt="" /></div>
                        <div className={["time notranslate", classes.link].join(" ")}>
                            {this.state.video?.type === "web/link" || this.state.video?.type === "personal/link" ? (
                                // eslint-disable-next-line react/jsx-no-target-blank
                                <a href={this.state.video.url} target="_blank">Ch: {this.state.video?.dial_number}</a>
                            ) : (
                                <a href={"https://calltv.kr/play/" + this.state.video?.dial_number}>Ch: {this.state.video?.dial_number}</a>
                            )}

                        </div>
                    </div>
                    <div className="video-card-body">
                        {this.state.video?.type === "web/link" || this.state.video?.type === "personal/link" ? (
                            // eslint-disable-next-line react/jsx-no-target-blank
                            <a href={this.state.video.url} target="_blank" className="video-title">
                                <div className="notranslate text-truncate">{this.state.video.title}</div>
                            </a>
                        ) : (
                            <NavLink to={"/play/" + this.state.video.dial_number} className="video-title">
                                <div className="notranslate text-truncate">{this.state.video.title}</div>
                            </NavLink>
                        )}

                        <div className="d-flex justify-content-start video-page text-success notranslate">
                            <div>
                                {this.state.video?.user_id ? (
                                    <NavLink to={"/UserVideos/" + this.state.video?.user_id} className="video-page text-success notranslate">
                                        <i className="fas fa-user"></i>&nbsp;
                                        {this.state.video?.user_name}
                                    </NavLink>
                                ) : ('')}
                            </div>
                            &nbsp;
                            <div>
                                {this.state.video?.sponsor ? (
                                    <NavLink to={"/" + this.state.video?.sponsorPage} className="video-page text-success notranslate">
                                        <i className="fas fa-fw fa-star"></i>&nbsp;
                                        {this.state.video?.sponsor}
                                    </NavLink>
                                ) : ('')}
                            </div>
                            &nbsp;
                            <div>
                                {this.state.video?.category ? (
                                    <NavLink to={`/sponsor/${this.state.video?.sponsorPage}/${this.state.video?.category}`} className="video-page text-success notranslate">
                                        <i className="far fa-list-alt"></i>&nbsp;
                                        {this.state.video?.category}
                                    </NavLink>
                                ) : ('')}
                            </div>

                        </div>
                        {this.props?.mysubmit && (
                            <div>
                                <Divider />
                                <MySubmitControl video={this.props.video}></MySubmitControl>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

}




export default VideoCard;
