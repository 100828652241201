import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { Button } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';
import { GoogleLogin } from 'react-google-login';
const { REACT_APP_GOOGLE_CLIENT_ID } = process.env;

class GoogleLoginButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            loading: false,
            success: false,
            error: null,
            showLoginPopup: false
        };
    }

    render() {
        const responseGoogle = (response) => {
            const googleUser = {
                id: response.googleId,
                email: response.profileObj.email,
                photoUrl: response.profileObj.imageUrl,
                idToken: response.tokenId,
                authToken: response.accessToken
            }
            this.props.onAuth(googleUser);
        }
        return (
            <div>
                <GoogleLogin
                    clientId={`${REACT_APP_GOOGLE_CLIENT_ID}`}
                    buttonText="Login"
                    render={renderProps => (
                        <Button onClick={renderProps.onClick} disabled={renderProps.disabled} block={true} size="large" icon={<GoogleOutlined />}>Login with Gmail</Button>
                    )}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                />

            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: (authUser) => dispatch(actions.loginWithGoogl(authUser)),
        showLoginPopup: (show) => dispatch(actions.showLoginPopup(show)),
    };
};
const mapStateToProps = (state) => {
    return {
        user: state.auth?.user,
        loading: state.auth?.loading,
        success: state.auth?.success,
        error: state.auth?.error,
        showLoginPopup: state.popup?.showLoginPopup
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(GoogleLoginButton);