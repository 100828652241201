import * as actionTypes from './avatarActionTypes';
import axios from 'axios';
const { REACT_APP_API_URL } = process.env;
const { REACT_APP_CMS_URL } = process.env;

export const avatar = (avatar) => {
    return {
        type: actionTypes.GET_AVATAR,
        avatar: avatar
    };
};


export const getAvatar = (userId) => {
    return (dispatch) => {
        axios
            .get(`${REACT_APP_API_URL}user/GetAvatar/${userId}`)
            .then((res) => {
                let avatarData =res.data.data
                if(avatarData.avatar){
                    avatarData.avatar=`${REACT_APP_CMS_URL}${avatarData.avatar}`
                }else if(avatarData.social_avatar){
                    avatarData.avatar=avatarData.social_avatar
                }
                dispatch(avatar(avatarData));
            })
            .catch((err) => {
                console.log(err);
            });
    };
};