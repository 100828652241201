import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import v1 from '../../../assets/img/v1.png';
import { GetYoutubeThumb } from '../../../Services/YoutubeService';
import classes from './VideoCardList.module.css';
class VideoCardList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            video: props.video,
            thumbnail: v1
        };
    }
    componentDidMount() {
        this.getThumbnail();
    }
    async getThumbnail() {
        const video = this.state.video;
        if (video.type === "video/youtube") {
            try {
                const thumb = GetYoutubeThumb(video.url);
                this.setState({ thumbnail: thumb })
            } catch (err) {
                this.setState({ thumbnail: v1 })
            }

        } else {
            this.setState({ thumbnail: v1 })
        }
    }
    render() {
        return (
            <div className="video-card video-card-list">
                <div className="video-card-image">
                    <NavLink to={"/play/" + this.state.video.dial_number} className="play-icon"><i className="fas fa-play-circle"></i></NavLink>
                    <img className={["img-fluid", classes.thumb].join(" ")} src={this.state.thumbnail} alt="" />
                </div>
                <div className="video-card-body">
                    <div className="btn-group float-right right-action">
                        <a href="/" className="right-action-link text-gray" data-toggle="dropdown" aria-expanded="false">
                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                            <a href="/" className="dropdown-item" ><i className="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                            <a href="/" className="dropdown-item" ><i className="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                            <a href="/" className="dropdown-item" ><i className="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                        </div>
                    </div>
                    <div className="video-title">
                        <NavLink to={"/play/" + this.state.video.dial_number} className="video-title">
                            <div className="notranslate">{this.state.video.title}</div>
                        </NavLink>
                    </div>
                    <div>
                        {this.state.video?.sponsor ? (
                            <NavLink to={"/SponsorVideos/" + this.state.video?.sponsor_id} className="video-page text-success notranslate">
                                {this.state.video?.sponsor}
                            </NavLink>
                        ) : ('')}
                    </div>
                    <span className="video-page text-success notranslate">
                        {this.state.video?.category ? (
                            <NavLink to={"/SponsorVideos/" + this.state.video?.category_id} className="video-page text-success notranslate">
                                {this.state.video?.category}
                            </NavLink>
                        ) : ('')}
                    </span>
                    <div className="video-view">
                        Ch: {this.state.video?.dial_number}
                    </div>
                </div>
            </div>
        );
    }
}

export default VideoCardList;