import React, { Component } from 'react';
import { connect } from 'react-redux';
import Title from '../../components/Title/Title';
import api from '../../tools/api';
import Spinner from '../../components/Spinner/Spinner';
import VideoCard from '../../components/Cards/VideoCard/VideoCard';
import { Nav, Sidebar, Footer } from '../../components/common';
import * as actions from '../../store/actions/index';
import { Alert } from 'antd';

class MySubmitPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user,
            authSuccess: this.props.auth.success,
            items: [],
            from: 0,
            limit: 4000,
            loading: false
        }
    }



    componentDidMount() {
        this.GetVideos(this.props.user?.id);

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.auth.success !== prevProps.auth.success) {
            this.GetVideos(this.props.user?.id);
        }
    }




    render() {
        // const columns = [
        //     {
        //         title: 'Channel',
        //         dataIndex: 'title',
        //         key: 'id',
        //         width: "200",
        //         render: (text, record) => (
        //             <div className="video-block section-padding">
        //                 <div className="row">
        //                     <VideoCard video={record} ></VideoCard>
        //                 </div>
        //             </div>
        //         )
        //     },
        //     {
        //         title: 'Gateway',
        //         dataIndex: 'gateway',
        //         key: "id"
        //     },
        // ]


        const showLogin = () => {
            this.props.showLoginPopup(true);
        };

        return (

            <div>
                <Nav></Nav>
                <Sidebar></Sidebar>
                <div id="wrapper">
                    <div id="content-wrapper">
                        <div className="container-fluid pb-0">
                            <div className="video-block section-padding">
                                {this.props.auth.success ? (
                                    <div className="row">
                                        <Title title={"My Submit"} showLine={false}></Title>
                                        {this.state.items.map((video) =>
                                            <VideoCard key={video.id + Math.random()} mysubmit={true} video={video}></VideoCard>
                                        )}
                                    </div>
                                ) :
                                    <div>
                                        {showLogin()}
                                        <Alert message="Please login to submit new channel" type="warning" />
                                    </div>
                                }
                            </div>
                            {this.state.loading && (<Spinner size={50}></Spinner>)}
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>

        )
    }

    GetVideos = (userId) => {
        this.setState({
            loading: true
        });
        api.get(`video/MySubmit/${userId}`)
            .then((res) => {
                const itemList = res.data.data
                let items = this.state.items
                itemList.forEach(item => {
                    item.key = item.id
                    items.push(item);
                });

                this.setState({
                    categories: items
                })
                const from = this.state.from + 1;
                const limit = this.state.limit;
                this.setState({
                    from: from + limit,
                    loading: false
                }, () => {
                    // console.log(this.state.items);
                });
            })
            .catch((err) => {
                this.setState({
                    loading: false
                });
                console.log(err);
            });
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showLoginPopup: (show) => dispatch(actions.showLoginPopup(show)),
        authInit: () => dispatch(actions.authInit())
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        auth: state.auth,
        authInit: state.authInit
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MySubmitPage);