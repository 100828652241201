import * as actionTypes from '../actions/submit/SubmitActionTypes';
import { updateObject } from '../utility';
const initialState = {
    video: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SUBMIT_VIDEO:
            return video(state, action);
        default:
            return state;
    }
}

const video = (state, action) => {
    return updateObject(state, action.video);
};
export default reducer;