import React, { Component } from 'react';
import { connect } from 'react-redux';
import api from '../../tools/api';
import VideoCard from '../../components/Cards/VideoCard/VideoCard';
import Title from '../../components/Title/Title';
import Spinner from '../../components/Spinner/Spinner';
import { Nav, Sidebar, Footer } from '../../components/common';
class FavoritePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user,
            videos: [],
            from: 0,
            limit: 12,
            loading: false
        }
    }

    componentDidMount() {
        this.GetVideos();
    }


    GetVideos = () => {
        this.setState({ loading: true });
        const post = {
            "user_id": this.state.user.id
        }
        api.post(`video/GetFavorite`, post)
            .then((res) => {
                this.setState({
                    videos: res.data.data,
                    loading: false
                }, () => {
                    // console.log(this.state.items);
                });
            })
            .catch((err) => {
                this.setState({ loading: false });
                console.log(err);
            });
    }
    render() {
        return (
            <div>
                <Nav></Nav>
                <Sidebar></Sidebar>
                <div id="wrapper">
                    <div id="content-wrapper">
                        <div className="container-fluid pb-0">
                            <div className="video-block section-padding">
                                <Title title='Favorite' showLine={false}></Title>
                                <div className="row">
                                    {this.state.videos.map((video) =>
                                        <VideoCard key={video.id + Math.random()} video={video}></VideoCard>
                                    )}
                                </div>
                                {this.state.loading && (<Spinner size={50}></Spinner>)}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    // console.log(state.auth);
    return {
        user: state.auth.user
    };
};
export default connect(mapStateToProps)(FavoritePage);