import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import s1 from '../../../assets/img/s1.png';
import UserAvatar from '../../UserAvatar/UserAvatar';

class UserCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            thumbnail: s1
        };
    }
    render() {
        return (
            <div className="col-xl-3 col-sm-6 mb-3">
                <div className="channels-card">
                    <NavLink to={`/UserVideos/${this.state.user?.id}`} className="channels-card-image">
                        <div>
                            <UserAvatar user={this.state.user}></UserAvatar>
                        </div>
                        <div className="channels-card-image-btn"><button type="button" className="btn btn-outline-danger btn-sm"><strong>{this.state.user?.total_video}</strong> Video</button></div>
                    </NavLink>
                    <div className="channels-card-body">
                        <div className="channels-title">
                            <NavLink to={`/UserVideos/${this.state.user?.id}`}>{this.state.user?.name}</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserCard
