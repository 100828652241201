import React, { Component } from 'react'
import Title from './../../components/Title/Title';
import VideoCard from '../../components/Cards/VideoCard/VideoCard';
import api from '../../tools/api';
import Spinner from '../../components/Spinner/Spinner';
import { Nav, Sidebar, Footer } from '../../components/common';
import SearchBox from '../../components/SearchBox/SearchBox';
class SearchResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: [],
            from: 0,
            limit: 12,
            key: this.props.match.params.key,
            loading: true
        };
        this.getVideos = this.getVideos.bind(this);
    }
    componentDidMount() {
        this.getVideos();
    }

    componentDidUpdate(prevProps, prevState) {
        const dialNumber = this.props.match.params.key;
        if (dialNumber !== prevProps.match.params.key) {
            this.getVideos();
        }
    }
    getVideos() {
        console.log(this.props.match.params.key);
        if (this.props.match.params.key === undefined) {
            return;
        }
        this.setState({
            videos: [],
            loading: true
        })
        const search = {
            search: this.props.match.params.key
        }
        api.post(`video/Search`, search)
            .then((res) => {
                const newVideos = res.data.data
                let videoList = this.state.videos
                newVideos.forEach(video => {
                    videoList.push(video);
                });
                const from = this.state.from + 1;
                const limit = this.state.limit;
                this.setState({
                    videos: videoList,
                    from: from + limit,
                    loading: false
                });
            })
            .catch((err) => {
                this.setState({
                    loading: false
                });
                console.log(err);
            });
    }

    render() {
        return (
            <div>
                <Nav></Nav>
                <Sidebar></Sidebar>
                <div id="wrapper">
                    <div id="content-wrapper">
                        <div className="container-fluid pb-0">
                            <SearchBox view={'mob'}></SearchBox>
                            {this.state.videos.length > 0 ? (
                                <div className="video-block section-padding">
                                    <div>
                                        {this.state.loading ? (
                                            <Spinner size={50}></Spinner>
                                        ) : ('')}
                                    </div>
                                    <div className="row">
                                        {/* <Title title={"Search for: " + this.state.key} showLine={false}></Title> */}
                                        {this.state.videos.map((video) =>
                                            <VideoCard key={video.id + Math.random()} video={video}></VideoCard>
                                        )}
                                    </div>
                                </div>
                            ) : ("")
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}

export default SearchResult;