import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Title from '../../components/Title/Title';
import { Nav, Sidebar, Footer } from '../../components/common'
import Submit from '../../components/Submit/Submit';
import { Alert } from 'antd';
class SubmitPage extends Component {

    render() {
        const showLogin = () => {
            this.props.showLoginPopup(true);
        };
        return (
            <div>
                <Nav></Nav>
                <Sidebar></Sidebar>
                <div id="wrapper">
                    <div id="content-wrapper">
                        <div className="container-fluid pb-0">
                            <div className="video-block section-padding">
                                {this.props.auth.success ? (
                                    <div>
                                        <Title title={"Submit"} showLine={false}></Title>
                                        <Submit />
                                    </div>
                                ) :
                                    <div>
                                        {showLogin()}
                                        <Alert message="Please login to submit new channel" type="warning" />
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        showLoginPopup: (show) => dispatch(actions.showLoginPopup(show)),
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubmitPage);
