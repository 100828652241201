import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
class LoginButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: false,
            loading: false
        };

    }
    render() {
        const showLogin = () => {
            this.props.showLoginPopup(true);
        };
        return (
            <div>
                {!this.props.isLogin && !this.props.loading ? (
                    <button onClick={showLogin} type="button" className="btn btn-outline-danger btn-sm">
                        <i className="fas fa-sign-in-alt fa-fw"></i> Login
                    </button>
                ) : ('')}
            </div >

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showLoginPopup: (show) => dispatch(actions.showLoginPopup(show)),
    };
};

const mapStateToProps = (state) => {
    return {
        isLogin: state.auth?.success,
        loading: state.auth?.loading,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginButton);