import React, { Component } from 'react';
import Title from '../../Title/Title';
import VideoCard from '../../Cards/VideoCard/VideoCard';
import Spinner from '../../Spinner/Spinner';
import api from '../../../tools/api';
import LoadMoreButton from '../../Buttons/LoadMoreButton/LoadMoreButton';
class NewVideos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: [],
            from: 0,
            limit: 32,
            loading: false
        };
    }
    componentDidMount() {
        this.getNewVideos(this.state.from, this.state.limit);
    }
    KPGPayment(response) {
        console.log(response);
    }
    getNewVideos = (from, limit) => {
        this.setState({
            loading: true
        });
        api.get(`video/NewVideos/${from}/${limit}`)
            .then((res) => {
                const newVideos = res.data.data
                let videoList = this.state.videos
                newVideos.forEach(video => {
                    videoList.push(video);
                });
                this.setState({
                    videos: videoList,
                    from: from + limit,
                    loading: false
                });

            })
            .catch((err) => {
                this.setState({
                    loading: false
                });
                console.log(err);
            });
    }

    render() {
        return (
            <div>
                {this.state.videos.length > 0 ? (
                    <div className="video-block section-padding">
                        {/* <ImportPayment callback={this.KPGPayment.bind(this)} ></ImportPayment> */}
                        <div className="row">
                            <Title title={"New Videos"} showLine={true}></Title>
                            {this.state.videos.map((video) =>
                                <VideoCard key={video.id + Math.random()} video={video}></VideoCard>
                            )}
                        </div>

                    </div>
                ) : ("")}
                <div>
                    {this.state.loading ? (
                        <Spinner size={50}></Spinner>
                    ) : (
                        <LoadMoreButton
                            loadMore={this.getNewVideos.bind(this)}
                            from={this.state.from}
                            limit={this.state.limit}
                        />
                    )}
                </div>
            </div>

        )
    }
}
export default NewVideos
