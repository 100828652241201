import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ScrollIntoView from './tools/ScrollIntoView';
import './assets/vendor/bootstrap/css/bootstrap.min.css'
import './assets/vendor/fontawesome-free/css/all.min.css'
import './assets/css/osahan.css';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';
import { GetItem } from './tools/LocalStorage';

import HomePage from './pages/HomePage/HomePage';
import PlayerPage from './pages/PlayerPage/PlayerPage';
import SponsorVideosPage from './pages/SponsorVideosPage/SponsorVideosPage';
import CategoryVideos from './pages/CategoryVideosPage/CategoryVideosPage';
import LogoutPopup from './components/popups/LogoutPopup/LogoutPopup';
import LoginPopup from './components/popups/LoginPopup/LoginPopup';
import SearchResult from './pages/SearchResult/SearchResult'
import Categories from './pages/CategoriesPage/CategoriesPage';
import SponsorsPage from './pages/SponsorsPage/CategoriesPage';
import DistributorsPage from './pages/DistributorsPage/DistributorsPage';
import AgentVideos from './pages/AgentVideosPage/AgentVideosPage';
import DistributorVideos from './pages/DistributorVideosPage/DistributorVideosPage';
import AgentsPage from './pages/AgentPage/AgentPage'
import LiveVideosPage from './pages/LiveVideosPage/LiveVideosPage';
import UserVideosPage from './pages/UserVideosPage/UserVideosPage';
import PlaylistVideos from './pages/PlaylistVideosPage/PlaylistVideosPage';
import ScrollNewsManagerPage from './pages/ScrollNewsManagerPage/ScrollNewsManagerPage';
import BannerManagerPage from './pages/BannerManagerPage/BannerManagerPage';

import './App.css';
import IamportReturnPage from './pages/IampodrReturnPage/IamportReturnPage';
import TransactionPage from './pages/TransactionPage/TransactionPage';
import MySubmitPage from './pages/MySubmitPage/MySubmitPage';
import ExtendReturnPage from './pages/ExtendReturnPage/ExtendReturnPage';
import FavoritePage from './pages/FavoritePage/FavoritePage';
import MyProfile from './pages/MyProfile/MyProfile';
import ChatSupportPage from './pages/ChatSupportPage/ChatSupportPage';
import BuyCardPage from './pages/BuyCardPage/BuyCardPage';
import PageReader from './pages/PageReader/PageReader';
import SponsorCategoryVideoPage from './pages/SponsorVideosPage/pages/SponsorCategoryVideoPage';
import SubmitPage from './pages/SubmitPage/SubmitPage';
import { ScrollVideo } from './pages/ScrollVideo/ScrollVideo';
import { PaymentSuccessPage } from './pages/PaymentSuccessPage/PaymentSuccessPage';
class App extends Component {
  componentDidMount() {
    const user = GetItem('user')
    if (user) {
      this.props.authInit();
    }
  }
  render() {
    return (
      <BrowserRouter>
        <ScrollIntoView>
          <Switch>
            <Route path="/" component={HomePage} exact />
            {/* <Route path="/page/submit" component={HomePage} exact />
            <Route path="/page/submit/:dial" component={HomePage} exact /> */}
            <Route path="/page/submit/:dial" component={HomePage} exact />
            <Route path="/play/:dial" component={PlayerPage} exact />
            <Route path="/:id" component={SponsorVideosPage} exact />
            <Route path="/sponsor/:id/:cat" component={SponsorCategoryVideoPage} exact />
            <Route path="/sponsor/:id/:cat/:scat" component={SponsorCategoryVideoPage} exact />
            <Route path="/categoryVideos/:id" component={CategoryVideos} exact />
            <Route path="/agentVideos/:id" component={AgentVideos} exact />
            <Route path="/distributorVideos/:id" component={DistributorVideos} exact />
            <Route path="/page/search/:key" component={SearchResult} exact />
            <Route path="/page/search/" component={SearchResult} exact />
            <Route path="/page/categories" component={Categories} exact />
            <Route path="/page/promo" component={SponsorsPage} exact />
            <Route path="/page/distributors" component={DistributorsPage} exact />
            <Route path="/page/community" component={AgentsPage} exact />
            <Route path="/page/live" component={LiveVideosPage} exact />
            <Route path="/userVideos/:id" component={UserVideosPage} exact />
            <Route path="/page/playlist-videos" component={PlaylistVideos} exact />
            <Route path="/page/read/:id" component={PageReader} exact />
            <Route path="/page/submit/" component={SubmitPage} />
            <Route path="/page/mychannel/" component={MySubmitPage} exact />
            <Route path="/page/transaction/" component={TransactionPage} exact />
            <Route path="/page/videos/" component={ScrollVideo} exact />
            <Route path="/page/videos/:id" component={ScrollVideo} exact />
          </Switch>
          {this.props.auth.success ? (
            <Switch>
              <Route path="/page/ScrollNews/" component={ScrollNewsManagerPage} />
              <Route path="/page/Banner/" component={BannerManagerPage} />
              <Route path="/page/payment/" component={IamportReturnPage} exact />
              <Route path="/page/extend/" component={ExtendReturnPage} exact />
              <Route path="/page/favorite/" component={FavoritePage} exact />
              <Route path="/page/profile/" component={MyProfile} exact />
              <Route path="/page/chat-support/" component={ChatSupportPage} exact />
              <Route path="/page/buy-card/" component={BuyCardPage} exact />
              <Route path="/page/payment-success/" component={PaymentSuccessPage} />

            </Switch>
          ) : null}
        </ScrollIntoView>

        {/* <Footer></Footer> */}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
        <LoginPopup></LoginPopup>
        <LogoutPopup></LogoutPopup>
      </BrowserRouter>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    authInit: () => dispatch(actions.authInit())
  };
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);

