import * as actionTypes from '../actions/popups/popupActionTypes';
import { updateObject } from '../utility';
const initialState = {
    showLoginPopup: false,
    action: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_LOGIN_POPUP:
            return showLoginPopup(state, action);
        default:
            return state;
    }
}

const showLoginPopup = (state, action) => {
    const obj = {
        showLoginPopup: action.show,
        action: action.action
    };
    return updateObject(state, obj);
};

export default reducer;
