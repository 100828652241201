import React, { Component } from 'react';
import api from '../../tools/api';
import Title from '../../components/Title/Title';
import { connect } from 'react-redux';
import { Table, Space, Button, Form, Input, Card, Popconfirm, Image } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import classes from './BannerManagerPage.module.css';
import ImageSelector from '../../components/ImageSelector/ImageSelector';
const { REACT_APP_CMS_URL } = process.env;

class BannerManagerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            scrolls: [],
            loading: false,
            adding: false,
            imageUrl: null,
            banner64: null
        };
    }
    componentDidMount() {
        this.GetScrollNews();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user !== this.state.user) {
            this.setState({
                user: nextProps.user
            }, () => {
                this.GetScrollNews();
            });

        }
    }


    GetScrollNews = () => {
        this.setState({
            loading: true,
            scrolls: []
        });
        api.get(`banner/get/${this.props.user.id}`)
            .then((res) => {
                const newScrolls = res.data.data
                let scrollList = [];
                newScrolls.forEach(item => {
                    item.key = item.id;
                    scrollList.push(item);
                });

                this.setState({
                    scrolls: scrollList,
                    loading: false
                }, () => {
                })

            })
            .catch((err) => {
                this.setState({
                    loading: false
                });
                console.log(err);
            });
    }

    onSubmit = (values) => {
        values.user = this.props.user.id;
        this.setState({ adding: true });
        const formData = new FormData();
        formData.append('user', this.props.user.id);
        formData.append('title', values.title);
        formData.append('link', values.link);
        formData.append('image', this.state.banner64);
        formData.append('last_image', values.image);
        api.post(`banner/add`, formData)
            .then((res) => {
                if (res.data.data.affectedRows > 0) {
                    this.GetScrollNews();
                }
                this.setState({ adding: false });
            })
            .catch(() => { this.setState({ adding: false }); })
    }

    delete = (values) => {
        this.setState({ loading: true });
        const banner = {
            id: values.id,
            user: this.props.user.id,
            DeleteFile: values.image
        }
        api.post(`banner/delete`, banner)
            .then((res) => {
                if (res.data.data.affectedRows > 0) {
                    this.GetScrollNews();
                }
                this.setState({ loading: false });
            })
            .catch(() => { this.setState({ loading: false }); })
    }

    bannerSelect = (image) => {
        this.setState({
            banner64: image
        }, () => {
            console.log(this.state.banner64);
        })

    }

    render() {

        const columns = [
            {
                title: 'Title',
                dataIndex: 'title',
                key: "title",
                width: 100,
            },
            {
                title: 'Link',
                dataIndex: 'link',
                key: 'link',
                width: 50,
            },
            {
                title: 'Image',
                key: 'link',
                width: 100,
                render: (text, record) => (
                    <div>
                        <Image
                            className={classes.banner}
                            width={200}
                            src={`${REACT_APP_CMS_URL}/${record?.image}`}
                        />
                    </div>
                )
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <Space size="middle">
                        <Popconfirm
                            title="Are you sure to delete this?"
                            onConfirm={() => this.delete(record)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" shape="circle" icon={<DeleteOutlined />} />
                        </Popconfirm>

                    </Space>
                )
            },
        ];
        return (
            <div id="content-wrapper">
                <div className="container-fluid pb-0">
                    <Title title={"Banner manager"} showLine={false}></Title>
                    <div className="mb-2">
                        <Card className={["d-flex justify-content-center", classes.card].join(" ")}>
                            <div className="mb-2">
                                <ImageSelector callback={this.bannerSelect.bind(this)} ></ImageSelector>
                            </div>
                            <Form
                                name="basic"
                                layout="inline"
                                onFinish={this.onSubmit}
                            >
                                <Form.Item
                                    label="Title"
                                    name="title"
                                    rules={[{ required: true, message: 'Please input title!' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Link"
                                    name="link"
                                    rules={[{ required: true, message: 'Please input link!' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item>
                                    <Button loading={this.state.adding} type="primary" htmlType="submit">
                                        Add
                                </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </div>
                    <div className="video-block section-padding">
                        <div className="rows">
                            <Table
                                size={'small'}
                                showHeader={true}
                                scroll={{ x: 1300 }}
                                loading={this.state.loading}
                                dataSource={this.state.scrolls}
                                columns={columns} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        auth: state.auth
    };
};
export default connect(mapStateToProps, null)(BannerManagerPage);
