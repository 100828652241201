import React, { Component } from 'react';
import s1 from '../../assets/img/no_profile.png';
const { REACT_APP_CMS_URL } = process.env;
class UserAvatar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.user !== nextProps.user) {
          this.setState({
            user: nextProps.user
          });
        }
      }
    render() {
        let image = '';
        if (this.state.user?.avatar) {
            image = `${REACT_APP_CMS_URL}${this.state.user?.avatar}`
        } else if (this.state.user?.social_avatar) {
            image = this.state.user?.social_avatar;
        }else{
            image = s1;
        }
        return (
            <img className="img-fluid" src={image} alt={this.state.user?.name} />
        );
    }
}



export default UserAvatar;