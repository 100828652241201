import React, { Component } from 'react'
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { Alert, Modal, Form, Input, Button } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import FacebookLoginButton from '../../Buttons/FacebookLoginButton/FacebookLoginButton';
import GoogleLoginButton from '../../Buttons/GoogleLoginButton/GoogleLoginButton';
import ForgotPasswordButton from '../../Buttons/ForgotPasswordButton/ForgotPasswordButton';
import ResetPasswordButton from '../../Buttons/ResetPasswordButton/ResetPasswordButton';
class LoginPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            loading: false,
            success: false,
            error: null,
            showLoginPopup: false,
        };

    }

    render() {
        const onFinish = (values) => {
            this.props.onAuth(values.email, values.password, this.props.action);
        };

        const handleCancel = () => {
            this.props.showLoginPoup(false);
        };
        return (
            <Modal
                title="Login"
                maskClosable={false}
                visible={this.props.showLoginPopup}
                footer={null}
                onOk={onFinish}
                onCancel={handleCancel}>
                {this.props.error?.message ? (
                    <Alert message={this.props.error?.message} type="error" showIcon />
                ) : ('')}

                <br></br>
                <Form
                    name="normal_login"
                    initialValues={{
                        remember: true,
                        email: '',
                        password: '',
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Email!',
                            },
                        ]}
                    >
                        <Input
                            prefix={<MailOutlined />}
                            placeholder="Email"
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Password!',
                            },
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="Password"
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            block="true"
                            size="large"
                            loading={this.props.loading}
                        >Login</Button>
                    </Form.Item>
                </Form>
                <div className="d-flex flex-column gap10px">
                    <FacebookLoginButton></FacebookLoginButton>
                    <GoogleLoginButton></GoogleLoginButton>
                    <ForgotPasswordButton></ForgotPasswordButton>
                    <ResetPasswordButton></ResetPasswordButton>
                </div>

                <div className="d-flex justify-content-around gap10px">

                </div>
            </Modal>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: (email, password, action) => dispatch(actions.login(email, password, action)),
        showLoginPoup: (show) => dispatch(actions.showLoginPopup(show)),
    };
};
const mapStateToProps = (state) => {
    return {
        user: state.auth?.user,
        loading: state.auth?.loading,
        success: state.auth?.success,
        error: state.auth?.error,
        showLoginPopup: state.popup?.showLoginPopup,
        action: state.popup?.action,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginPopup);