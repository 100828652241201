import React, { Component } from 'react';
import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';
import UserAvatar from '../../../../components/UserAvatar/UserAvatar';
class ChannelName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video: this.props.video,
      user: {
        avatar: this.props.video.avatar,
        social_avatar: this.props.video.social_avatar
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.video !== nextProps.video) {
      this.setState({
        video: nextProps.video
      });
    }
  }
  render() {
    const user = {
      avatar: this.props.video.avatar,
      social_avatar: this.props.video.social_avatar
    }
    return (
      <div className="single-video-author box mb-3">
        <NavLink to={`/UserVideos/${this.state.video?.user_id}`} className="float-right"><button className="btn btn-danger" type="button">View more</button> </NavLink>
        <UserAvatar className="img-fluid" user={user}></UserAvatar>
        {/* <img className="img-fluid" src={s4} alt="" /> */}
        <p><NavLink to={`/UserVideos/${this.state.video?.user_id}`}><strong>{this.state.video?.user_name}</strong></NavLink> <span title="" data-placement="top" data-toggle="tooltip" data-original-title="Verified"><i className="fas fa-check-circle text-success"></i></span></p>
        {this.state.video?.submit_date && (
          <small>
            <Moment fromNow ago>{this.state.video?.submit_date}</Moment> ago
          </small>
        )}
      </div>
    );
  }
}

export default ChannelName;