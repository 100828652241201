import React from 'react'
import { NavLink } from 'react-router-dom';
import LogoImg from '../../assets/img/calltv.png';
import classes from './Logo.module.css';
const Logo = () => {
    return (
        <NavLink className="navbar-brand mr-1" to="/">
            <img className={['img-fluid', classes.logo].join(' ')} alt="" src={LogoImg} />
        </NavLink>
    )
}

export default Logo
