import React, { Component } from 'react';
import classes from './Footer.module.css';
import { Row, Col, Divider } from 'antd';
import { NavLink } from 'react-router-dom';
class Footer extends Component {
    render() {
        return (
            <footer className={classes.bgSecond}>
                <div className="container">
                    <p>
                        Warning
                        법적고지 : 주식회사 인방그룹은 영상 플랫폼제공자 및 통신판매중개자이며, 영상제작 및 통신판매의 당사자가 아닙니다. 영상의 내용 혹은 상품, 상품정보, 거래에 관한 의무와 책임은 영상을 올린 사람 그리고 판매자에게 있습니다.
                    </p>
                    <Row justify="center" align="middle" gutter={16}>
                        <Col span={11}>
                            <NavLink to={"/page/read/tnc_app"} >
                                Terms &nbsp; Condition
                            </NavLink>
                        </Col>
                        <Col span={2}><Divider type="vertical" orientation="center" className={classes.divider} /></Col>
                        <Col span={11}>
                            <NavLink to={"/page/read/calltv_privacy_policy_app"} >
                                Privacy Policy
                            </NavLink>
                        </Col>
                    </Row>
                </div>
            </footer>
        );
    }
}

export default Footer;