import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { Button, Steps, Card, Image, Select, Alert, Input, Modal } from 'antd';
import { Row, Col } from 'antd';
import classes from './Submit.module.css';
import CheckChannel from './components/CheckChannel/CheckChannel';
import GetCardInfo from './components/GetCardInfo/GetCardInfo';
import PhoneNumberComponent from './components/PhoneNumberComponent/PhoneNumberComponent';
import Text from '../Text/Text';
import api from '../../tools/api';
import InfoButton from '../Buttons/InfoButton/InfoButton';


class Submit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            dial_number: '',
            card_number: '',
            typeList: [],
            categoryList: [],
            country: "Korea, Republic Of",
            title: "",
            country_code: 'KR',
            dial_code: '+82',
            phone: '',
            user_id: '7',
            type: '',
            url: "",
            payment_type: 'card',
            payment: '',
            category: "",
            errorMsg: "",
            isValidVideoInfo: false,
            submitting: false,
            home_url: "",
            extra_text: "",
            extra_link: "",
            product_card: "",
            submit_type: "submit_card",
            product: null,
            validCard: false,
            card: null,
            sponsorCategory: "0"
        };
    }

    next = () => {
        if (this.state.current < 5) {
            this.setState({
                current: this.state.current + 1
            });
        }
    };

    prev = () => {
        if (this.state.current > 0) {
            this.setState({
                current: this.state.current - 1
            });
        }
    };

    stepOne = (result) => {
        console.log(result);
        if (!result.found) {
            this.setState({
                current: 1,
                dial_number: result.channelNumber
            })
        }
    }

    steVideoInfo(info) {
        if (info.type === 'submit_card') {
            this.setState({
                card: info,
                card_number: info.number,
            });
        } else if (info.type === 'product_card') {
            this.setState({
                card: info,
                title: info.product.name,
                url: info.product.url,
                category: info.product.category,
                type: info.product.videoType,
                card_number: info.number
            });
        }
    }
    onChangeCategory = (value) => {
        this.setState({
            category: value
        });
    }
    onChangeScat = (value) => {
        this.setState({
            sponsorCategory: value
        });
    }

    onChangeTitle = (value) => {
        this.setState({
            title: value.target.value
        }, () => {
        })
    }

    onChangeURL = (value) => {
        this.setState({
            url: value.target.value
        }, () => {
            // checkVideoInfo();
        })
    }

    onChangeType = (value) => {
        this.setState({
            type: value
        }, () => {
            // checkVideoInfo();
        })
    }

    selectedPhone = (value) => {
        this.setState({
            country: value.countryName,
            country_code: value.countryCode,
            dial_code: value.dialCode,
            phone: value.phoneNumber
        }, () => {
            // console.log(this.state);
        })
    }

    onChangeHomeURL = (value) => {
        this.setState({
            home_url: value.target.value
        }, () => {
        })
    }

    onChangeButtonText = (value) => {
        this.setState({
            extra_text: value.target.value
        }, () => {
        })
    }

    onChangeButtonURL = (value) => {
        this.setState({
            extra_link: value.target.value
        }, () => {
        })
    }


    onSubmit = () => {
        if (!this.state.user_id) {
            Modal.error({
                title: "Error",
                content: (
                    <div>
                        User not found;
                    </div>
                )
            });
            return;
        }
        const submitData = {
            country: this.state.country,
            title: this.state.title,
            dial_number: this.state.dial_number,
            country_code: this.state.country_code,
            dial_code: this.state.dial_code,
            phone: this.state.phone,
            user_id: this.props.auth.user?.id,
            type: this.state.type,
            url: this.state.url,
            payment_type: this.state.payment_type,
            payment: this.state.payment,
            card_number: this.state.card_number,
            category: this.state.category,
            home_url: this.state.home_url,
            extra_text: this.state.extra_text,
            extra_link: this.state.extra_link,
            sponsorCategory: this.state.sponsorCategory,
        };
        // console.log(submitData)
        this.setState({
            submitting: true
        });

        try {
            api.post(`video/add`, submitData)
                .then((res) => {
                    Modal.info({
                        title: "Success",
                        content: (
                            <div>
                                Submitted successfully!
                            </div>
                        )
                    });
                    window.location.href = '/';
                    this.setState({
                        submitting: false
                    });
                }).catch(error => {
                    console.error(error);
                    Modal.error({
                        title: "Error",
                        content: (
                            <div>
                                {error.response.data?.message}
                            </div>
                        )
                    });
                    this.setState({
                        submitting: false
                    });
                })
        } catch (error) {
            console.error(error);
        }

    }

    render() {
        const { Step } = Steps;
        const { Option } = Select;
        return (
            <div className="submitPage">
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Steps size="small" current={this.state.current}>
                            <Step />
                            <Step />
                            <Step />
                            <Step />
                        </Steps>
                    </Col>
                    <Col span={24}>
                        <Card className={classes.submitBox} bordered={false}>
                            {this.state.current === 0 && (
                                <div>
                                    <Text text="Channel number:"></Text>
                                    <CheckChannel channelNumber={this.state.dial_number} callback={this.stepOne.bind(this)} />
                                </div>

                            )}

                            {this.state.current === 1 && (
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <Text text="Card number:"></Text>
                                        <GetCardInfo cardNumber={this.state.card_number} callback={this.steVideoInfo.bind(this)}></GetCardInfo>
                                    </Col>
                                    {this.state.card?.type === 'product_card' && this.state.card?.status === 'not_used' && (
                                        <Row gutter={[16, 16]} span={24} justify="space-around" align="middle">
                                            <Col span={12}>
                                                <Image
                                                    src={this.state.card.product?.image}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <div>Name: {this.state.card?.product?.name}</div>
                                                <div>Package: {this.state.card?.packageName}</div>
                                                <div>Sponsor: {this.state.card?.sponsorName}</div>
                                            </Col>
                                        </Row>
                                    )}
                                    {this.state.card?.type === "submit_card" && this.state.card?.status === 'not_used' && (
                                        <Row gutter={[16, 16]} span={24} justify="space-around" align="middle">
                                            <Col span={24}>
                                                <div>Package: {this.state.card?.packageName}</div>
                                                <div>Sponsor: {this.state.card?.sponsorName}</div>
                                            </Col>
                                        </Row>

                                    )}
                                    {this.state.card?.status === 'used' && (
                                        <Col span={24}>
                                            <Alert message="Card number already used" type="error" />
                                        </Col>

                                    )}

                                </Row>

                            )}

                            {this.state.current === 2 && (
                                <div className="d-flex flex-column clearfix">
                                    <PhoneNumberComponent
                                        country={this.state.country}
                                        country_code={this.state.country_code}
                                        dial_code={this.state.dial_code}
                                        phone={this.state.phone}
                                        callback={this.selectedPhone.bind(this)}>
                                    </PhoneNumberComponent>
                                </div>
                            )}

                            {this.state.current === 3 && (
                                <Row>
                                    <Col span={24}>
                                        <Text text="Title:"></Text>
                                        <Input
                                            className="mb-2"
                                            size={'large'}
                                            value={this.state.title}
                                            onChange={this.onChangeTitle}
                                            suffix={
                                                <InfoButton title="Title" content="<p style='text-align:justify;'>Please write the channel or video title</p>" />
                                            }
                                        />
                                    </Col>

                                    <Col span={24}>
                                        <Text text="URL:"></Text>
                                        <Input
                                            className="mb-2"
                                            size={'large'}
                                            onChange={this.onChangeURL}
                                            value={this.state.url}
                                            suffix={
                                                <InfoButton title="URL" content="<p style='text-align:justify;'>Copy the domain address (URL) of the video image source and paste it here</p>" />
                                            }
                                        />
                                    </Col>

                                    <Col span={24}>
                                        <Text text="Category:"></Text>
                                        <Row align="middle">
                                            <Col span={23} >
                                                <Select
                                                    className="mb-2"
                                                    style={{ width: '100%' }}
                                                    placeholder="Select category"
                                                    size={'large'}
                                                    value={this.state.category}
                                                    onChange={this.onChangeCategory}>
                                                    {this.state.card.cat.map((cat) =>
                                                        <Option key={cat?.id} value={cat?.id}>{cat?.name}</Option>
                                                    )}

                                                </Select>
                                            </Col>
                                            <Col span={1}>
                                                <InfoButton title="Category" content="<p style='text-align:justify;'>Copy the domain address (URL) of the video image source and paste it here</p>" />
                                            </Col>
                                        </Row>

                                    </Col>

                                    {this.state.card?.scat && (
                                        <Col span={24}>
                                            <Text text="Sponsor category:"></Text>
                                            <Row align="middle">
                                                <Col span={23} >
                                                    <Select
                                                        block={true}
                                                        className="mb-2"
                                                        style={{ width: '100%' }}
                                                        placeholder="Select category"
                                                        size={'large'}
                                                        value={this.state.scat}
                                                        onChange={this.onChangeScat}>
                                                        {this.state.card?.scat.map((cat) =>
                                                            <Option key={cat?.id} value={cat?.id}>{cat?.name}</Option>
                                                        )}
                                                    </Select>
                                                </Col>
                                                <Col span={1}>
                                                    <InfoButton title="Sponsor category" content="<p style='text-align:justify;'>Select category for sponsor</p>" />
                                                </Col>
                                            </Row>
                                        </Col>
                                    )}
                                    {this.state.card?.videoType.length > 0 && (
                                        <Col span={24}>
                                            <Text text="Video type:"></Text>
                                            <Row align="middle">
                                                <Col span={23} >
                                                    <Select className="mb-2"
                                                        style={{ width: '100%' }}
                                                        placeholder="Select a type"
                                                        size={'large'}
                                                        value={this.state.type}
                                                        onChange={this.onChangeType}
                                                    >
                                                        {this.state.card?.videoType.map((type) =>
                                                            <Option key={type?.id} value={type?.type}>{type?.type_name}</Option>
                                                        )}
                                                    </Select>
                                                </Col>
                                                <Col span={1}>
                                                    <InfoButton title="Video type" content="<p style='text-align:justify;'>Please select the source from which the video footage was taken</p>" />
                                                </Col>
                                            </Row>

                                        </Col>
                                    )}

                                    <Col span={24}>
                                        <Text text="Home URL:"></Text>
                                        <Input
                                            className="mb-2"
                                            size={'large'}
                                            value={this.state.home_url}
                                            onChange={this.onChangeHomeURL}
                                            suffix={
                                                <InfoButton title="Home URL" content="<p style='text-align:justify;'>
                                                The 'Home URL' here is the link address of the button to be displayed on the right side of the video
                                                Please copy and paste the URL of homepage, shopping mall, and various SNS addresses 
                                                </p>" />
                                            }
                                        />
                                    </Col>

                                    <Col span={24}>
                                        <Text text="Button Text:"></Text>
                                        <Input
                                            className="mb-2"
                                            size={'large'}
                                            value={this.state.extra_text}
                                            onChange={this.onChangeButtonText}
                                            suffix={
                                                <InfoButton title="Button Text" content="<p style='text-align:justify;'>
                                                'Text' box is the title blank of the button to be displayed on the right side of the video
                                                Please put the appropriate title of various inquiries or payments related to the video (e.g., ordering, inquiring, paying, donating, booking, etc.)
                                                </p>" />
                                            }
                                        />
                                    </Col>

                                    <Col span={24}>
                                        <Text text="Button URL:"></Text>
                                        <Input
                                            className="mb-2"
                                            size={'large'}
                                            value={this.state.extra_link}
                                            onChange={this.onChangeButtonURL}
                                            suffix={
                                                <InfoButton title="Button URL" content="<p style='text-align:justify;'>
                                                The button URL box here is a place where you copy and paste the link address of the order site, payment site, or other sites that you move when you press the text button 5. (for e.g. : paste product payment site  of shopping mall)
                                                </p>" />
                                            }
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Card>
                    </Col>

                    <Col span={24}>
                        <Row gutter={[16, 16]} justify="space-between" align="center">
                            <Col span={12}>
                                {this.state.current > 0 && (
                                    <Button block={true} type="primary" onClick={() => this.prev()}>
                                        Back
                                    </Button>
                                )}
                            </Col>
                            <Col span={12}>
                                {this.state.current === 1 && (
                                    <Button disabled={this.state.card === null || this.state.card?.status === 'used'} block={true} type="primary" onClick={() => this.next()}>
                                        Next
                                    </Button>
                                )}
                                {this.state.current === 2 && (
                                    <Button disabled={this.state.phone === ''} block={true} type="primary" onClick={() => this.next()}>
                                        Next
                                    </Button>
                                )}
                                {this.state.current === 3 && (
                                    <Button disabled={false} block={true} type="primary" onClick={() => this.onSubmit()}>
                                        Submit
                                    </Button>
                                )}
                            </Col>
                        </Row>

                    </Col>
                </Row>



            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        auth: state.auth,
        action: state.popup.action
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showLoginPopup: (show) => dispatch(actions.showLoginPopup(show, "SUBMIT")),
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(Submit);