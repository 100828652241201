import React, { Component } from 'react';
import classes from './NavbarComponent.module.css';
import { Row, Col } from 'antd';
import { NavLink } from 'react-router-dom';
import SearchButton from '../../components/SearchButton/SearchButton';
class NavbarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            video: props.video,
        };
    }
    render() {
        return (
            <div>
                <div className={classes.navbar}>
                    <div className="container">
                        <Row justify="space-around">
                            <Col span={2}>
                                <div className={classes.contentLeft}>
                                    <NavLink to={this.props.backLink} className={classes.sidebar} >
                                        <i className="fa fa-arrow-left"></i>
                                    </NavLink>
                                </div>
                            </Col>
                            <Col span={20}>
                                <div className={classes.contentCenter}>
                                    <h1 className="notranslate">{this.props.title}</h1>
                                </div>
                            </Col>
                            <Col span={2}>
                                <div className={classes.contentRight}>
                                    <SearchButton page={this.props.page} cat={this.props.cat} scat={this.props.scat}></SearchButton>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default NavbarComponent;