import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Button, Select, Modal } from 'antd';
import api from '../../../tools/api';
import Text from '../../Text/Text';
const { REACT_APP_IMP_CODE, REACT_APP_IMP_PG, REACT_APP_IMP_RETURN_URL } = process.env;

class KoreanPaymentButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            video: this.props.video,
            packages: [],
            package: null,
            loading: false,
            paymentFor: this.props.paymentFor
        }
    }

    componentDidMount() {
        // console.log(this.state.video);
        this.GetPackage();

        const submitVideo = {
            country: this.state.video.country,
            title: this.state.video.title,
            dial_number: this.state.video.dial_number,
            country_code: this.state.video.country_code,
            dial_code: this.state.video.dial_code,
            phone: this.state.video.phone,
            user_id: this.props.user?.id,
            type: this.state.video.type,
            url: this.state.video.url,
            payment_type: this.state.video.payment_type,
            payment: this.state.video.payment,
            card_number: this.state.video.card_number,
            category: this.state.video.category,
            screen_type: this.state.video.screen_type,
            banner: this.state.video.banner,
            scroll_text: this.state.video.scroll_text,
            scroll_news: this.state.video.scroll_news,
            bottom_banner_right: this.state.video.bottom_banner_right,
            bottom_banner_right_url: this.state.video.bottom_banner_right_url,
            home_url: this.state.video.home_url,
            extra_text: this.state.video.extra_text,
            extra_link: this.state.video.extra_link,
            packageId: this.state.video.packageId,
        };
        this.setState({ video: submitVideo }, () => {
            // console.log(this.state.video);
        });
    }

    GetPackage() {
        api.get(`card/GetPackage`)
            .then((res) => {
                this.setState({
                    packages: res.data.data
                })
            }, (err) => { })
    }
    onClickPayment = () => {
        const custom_data = {
            paymentFor: this.state.paymentFor,
            video: this.state.video,
            extend: {
                video_id: this.props.video.id,
                user_id: this.props.user?.id,
                packageId: this.state.package.id
            }
        }
        const merchant_uid = `mid_${new Date().getTime()}`
        this.setState({ loading: true });
        const { IMP } = window;
        IMP.init(REACT_APP_IMP_CODE);
        const data = {
            pg: REACT_APP_IMP_PG,
            merchant_uid: merchant_uid,
            amount: this.state.package.price_won,
            name: this.state.package.name,
            packageId: this.state.package.id,
            buyer_name: this.props.user.name,
            buyer_email: this.props.user.email,
            buyer_addr: this.props.user.country,
            m_redirect_url: REACT_APP_IMP_RETURN_URL,
            custom_data: custom_data
        };
        IMP.request_pay(data, this.callback);
    }

    callback = (response) => {
        this.setState({ loading: false });
        let { success, error_msg } = response;
        success = true;
        console.log(response);
        if (success) {
            Modal.success({
                title: 'Submit',
                okText: 'Ok',
                onOk() {
                    window.location.href = '';
                },
                content: (
                    <div>
                        <p>Payment successful!</p>
                    </div>
                )
            });

        } else {
            Modal.error({
                title: 'Unable to make payment',
                content: (
                    <div>
                        <p>{error_msg}</p>
                    </div>
                )
            });
        }

        // this.props.callback(payment);
    }
    render() {
        const { Option } = Select;
        const onChangePackage = (value) => {
            const { packages } = this.state;
            const pkg = packages.find(item => item.id === value);
            this.setState({
                package: pkg
            });
        }
        return (

            <div className="d-flex flex-column clearfix">
                <Text text="Package"></Text>
                {this.state.packages.length > 0 && (
                    <Select className="mb-2"
                        style={{ width: '100%' }}
                        size={'large'}
                        value={this.state.type}
                        onChange={onChangePackage}
                    >
                        {this.state.packages.map((pkg) =>
                            <Option key={pkg?.id} value={pkg.id}>{`${pkg?.name} Price: ${pkg?.price_won} ￦`}</Option>
                        )}
                    </Select>
                )}
                {this.state.package && (

                    <Button loading={this.state.loading} onClick={this.onClickPayment} type="primary">Pay now</Button>
                )}

            </div>

        )
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    };
};


export default connect(mapStateToProps, null)(KoreanPaymentButton);