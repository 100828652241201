import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Popconfirm, Divider } from 'antd';
import api from '../../../tools/api';
import {DeleteOutlined } from '@ant-design/icons';
import ExtendButton from '../../Buttons/ExtendButton/ExtendButton';
import classes from './MySubmitControl.module.css'
import EditButton from '../../Buttons/EditButton/EditButton';

class MySubmitControl extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: this.props.user,
            video: this.props.video,
            deleting: false
        }
    }

    render() {
        const { deleting } = this.state;
        return (
            <div className="d-flex flex-column  justify-content-center">
                <div className="d-flex justify-content-between text-muted bg-light">
                    <div className={classes.dateText}><span>Submit: </span>{moment(this.state.video?.submit_date).format('DD-MM-YYYY')}</div>
                    <div className={classes.dateText}><span>Expired: </span>{moment(this.state.video?.expire_date).format('DD-MM-YYYY')}</div>
                </div>
                <Divider />
                <div className="d-flex justify-content-around gap5px">
                    <div className={classes.btnIcon} >
                        <ExtendButton user={this.state.user} video={this.state.video} />

                    </div>
                    <div className={classes.btnIcon} >
                        <EditButton video={this.state.video} ></EditButton>
                    </div>

                    <div>
                        <Popconfirm onConfirm={this.delete} title={`Wanna delete this video?`} okText="Yes" cancelText="No">
                            <Button loading={deleting} type="text" shape="circle" icon={<DeleteOutlined />}></Button>
                        </Popconfirm>
                    </div>
                </div>
            </div>

        );
    }

    delete = () => {
        this.setState({ deleting: true });
        api.delete(`video/delete/${this.state.video?.id}/${this.state.user?.id}`)
            .then((res) => {
                this.setState({ deleting: false });
                if (res.data.status === 200) {
                    window.location.href = '';
                }
            })
            .catch((err) => {
                this.setState({ deleting: false });
            });
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    };
};
export default connect(mapStateToProps, null)(MySubmitControl);
