import React, { Component } from 'react';
import NavbarComponent from '../components/NavBar/NavbarComponent';
import api from '../../../tools/api';
import { Row, Col, Alert } from 'antd';
import Footer from '../components/Footer/Footer';
import classes from './SponsorCategoryVideoPage.module.css';
import VideoCard from '../components/VideoCard/VideoCard';
import LoadMoreButton from '../../../components/Buttons/LoadMoreButton/LoadMoreButton';
import SponsorSlider from '../components/Slider/SponsorSlider';
class SponsorCategoryVideoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: [],
            from: 0,
            limit: 2,
            cat: null,
            scat: null,
            loading: false
        };
        // this.getVideos = this.getVideos.bind(this);
    }
    componentDidMount() {
        this.getVideos(this.state.from, this.state.limit);
    }
    selectCategory = (cat) => {
        this.setState({
            cat: cat
        }, () => {
            this.getVideos(this.state.from, this.state.limit);
        })
    }
    selectSponsorCategory = (scat) => {
        this.setState({
            scat: scat
        }, () => {
            this.getVideos(this.state.from, this.state.limit);
        })
    }

    getVideos = (from, limit) => {
        this.setState({
            loading: true
        })
        let scat = this.props.match.params.scat;
        if (!scat) {
            scat = 'null';
        }
        let URL = `video/SponsorVideos/${this.props.match.params.id}/${from}/${limit}/${this.props.match.params.cat}/${scat}`;
        console.log(URL);
        api.get(URL)
            .then((res) => {
                const newVideos = res.data.data
                let videoList = this.state.videos
                newVideos.forEach(video => {
                    videoList.push(video);
                });

                this.setState({
                    videos: videoList,
                    loading: false,
                })

            })
            .catch((err) => {
                console.log(err);
            });
    }
    render() {
        return (
            <div className={classes.page}>
                <NavbarComponent backLink={`/${this.props.match.params.id}`} title={this.props.match.params.cat} page={this.props.match.params.id} cat="null" scat="null"></NavbarComponent>
                <div className={[classes.newVideo].join(" ")}>
                    <SponsorSlider page={this.props.match.params.id}></SponsorSlider>
                    <Col span={24} className={classes.container}>

                        {this.state.videos.length > 0 ? (
                            <Row gutter={[16, 24]}>
                                {this.state.videos.map((video) =>
                                    <Col span={24}>
                                        <VideoCard linked={false} page={this.props.match.params.id} key={video.id + Math.random()} video={video}></VideoCard>
                                    </Col>
                                )}
                            </Row>
                        ) :
                            <div>
                                <Row justify="center" align="middle" className={classes.container}>
                                    <Alert message="Video not found in this category" type="warning" />
                                </Row>
                            </div>
                        }
                        <br></br>
                        <Row justify="center">
                            <LoadMoreButton
                                loadMore={this.getVideos.bind(this)}
                                from={this.state.from}
                                limit={this.state.limit}
                            />
                        </Row>
                    </Col>

                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export default SponsorCategoryVideoPage;