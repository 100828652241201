import React, { Component } from 'react';
import { Select } from 'antd';
import api from '../../../../tools/api';
import classes from './CategorySelector.module.css';
const { Option } = Select;
class CategorySelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sponsors: [],
            default: ''
        };
        this.GetSponsor = this.GetSponsor.bind(this);
    }
    componentDidMount() {
        this.GetSponsor();
    }
    GetSponsor() {
        api.get(`video/GetCategories/0/10000`)
            .then((res) => {
                this.setState({
                    sponsors: res.data.data,
                    default: res.data.data[0].name
                })
            })
            .catch((err) => {
                console.log(err);
            });
    }


    render() {
        function onSearch(val) {
            console.log('search:', val);
        }
        return (
            <div >
                {
                    this.state.sponsors.length > 0 ? (
                        <Select
                            className={classes.searchBox}
                            showSearch
                            placeholder="Category"
                            optionFilterProp="children"
                            onChange={this.props.selector}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Option key={8888876} value='null'>Show all</Option>
                            {this.state.sponsors.map((cat) =>
                                <Option key={cat.id} value={cat.name}><div className="notranslate" >{cat.name}</div></Option>
                            )}
                        </Select>
                    ) : null
                }
            </div>
        );
    }
}

export default CategorySelector;

// key={cat.id} category={cat}