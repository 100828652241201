import React, { Component } from 'react'
import Title from '../../components/Title/Title';
import VideoCard from '../../components/Cards/VideoCard/VideoCard';
import api from '../../tools/api';
class DistributorVideosPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: [],
            from: 0,
            limit: 12
        };
        this.getVideos = this.getVideos.bind(this);
    }
    componentDidMount() {

        this.getVideos();
    }
    getVideos() {
        api.get(`video/DistributorVideos/${this.props.match.params.id}/${this.state.from}/${this.state.limit}`)
            .then((res) => {
                const newVideos = res.data.data
                let videoList = this.state.videos
                newVideos.forEach(video => {
                    videoList.push(video);
                });

                this.setState({
                    videos: videoList
                })
                const from = this.state.from + 1;
                const limit = this.state.limit;
                this.setState({
                    from: from + limit
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        return (
            <div id="content-wrapper">
                <div className="container-fluid pb-0">
                    {this.state.videos.length > 0 ? (
                        <div className="video-block section-padding">
                            <div className="row">
                                <Title title={"Distributor: " + this.state.videos[0]?.distributor} showLine={false}></Title>
                                {this.state.videos.map((video) =>
                                    <VideoCard key={video.id + Math.random()} video={video}></VideoCard>
                                )}
                            </div>
                            <button onClick={this.getVideos} type="button" className="btn btn-primary">Load more...</button>
                        </div>
                    ) : ("")
                    }
                </div>
            </div>
        )
    }
}
export default DistributorVideosPage