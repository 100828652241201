import React, { Component } from 'react';
import Title from '../../components/Title/Title';
import api from '../../tools/api';
import { Modal } from 'antd';
import { GetItem } from '../../tools/LocalStorage'
import { connect } from 'react-redux';

class ExtendReturnPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user,
            video: null,
            imp_uid: null,
            imp_success: null,
            error_msg: null,
            loading: false,
            payment: null,

        }
    }
    componentDidMount() {
        const imp_success = true;//(new URL(window.location.href)).searchParams.get('imp_success');
        const error_msg = (new URL(window.location.href)).searchParams.get('error_msg');
        const payment = GetItem('EXTEND');
        this.setState({
            payment: payment,
            imp_success: imp_success,
            error_msg: error_msg,
        }, () => {
            if (imp_success === true) {
                console.log(this.state.payment);
                this.onSubmit();
            }
        });
    }

    onSubmit = () => {
        try {
            console.log(this.state.payment);
            this.setState({ loading: true });
            const payment =
            {
                "gateway": this.state.payment.gateway,
                "video_id": this.state.video.id,
                "status": this.state.payment.status,
                "trans_id": this.state.payment.trans_id,
                "user_id": this.state.user.id,
                "amount": this.state.payment.amount,
                "currency":this.state.payment.currency,
                "duration": this.state.payment.duration,
                "packageId": this.state.payment.packageId,
                "remarks": `extended by` + this.state.payment.gateway
            };
            api.post('video/ExtendByPayment', payment)
                .then((res) => {
                    this.setState({ loading: false });
                    console.log(res.data);
                    if (res.data.status === 200) {
                        Modal.success({
                            title: 'Success',
                            content: 'Time extended successfully',
                        });
                        window.location.href = '';
                    } else {
                        Modal.error({
                            title: 'Error',
                            content: res.data.message,
                        });
    
                    }
                })
                .catch((err) => {
                    console.log(err);
                    Modal.error({
                        title: 'Error',
                        content: 'Unable extend',
                    });
                    this.setState({ loading: false });
                });

        } catch (error) {
            console.log(error);
            Modal.error({
                title: "Error",
                content: (
                    <div>
                        Not submitted
                    </div>
                )
            });
        }

    }

    render() {

        return (
            <div id="content-wrapper">
                <div className="container-fluid pb-0">
                    <div className="video-block section-padding">
                        <Title title={"Extend"} showLine={false}></Title>
                        <p>{this.state.error_msg}</p>
                    </div>
                </div>
            </div>
        );
    }
}





const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    };
};
export default connect(mapStateToProps, null)(ExtendReturnPage);