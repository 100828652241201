import { Modal, Radio, Space, Divider, Input } from 'antd';
import { ExclamationCircleOutlined, DollarOutlined, CheckCircleOutlined } from '@ant-design/icons';


export const infoDialog = (title, message) => {
  return new Promise((resolve, reject) => {
    Modal.info({
      title: title,
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() {
        resolve(true);
      },
    });
  });
}

export const successDialog = (title, message) => {
  return new Promise((resolve, reject) => {
    Modal.success({
      title: title,
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() {
        resolve(true);
      },
    });
  });
}
export const errorDialog = (title, message) => {
  return new Promise((resolve, reject) => {
    Modal.error({
      title: title,
      content: (
        <div>
          <p>{message}</p>
        </div>
      ),
      onOk() {
        resolve(true);
      },
    });
  });
}
const { confirm } = Modal;
export const confirmDialog = (title, message) => {
  return new Promise((resolve, reject) => {
    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      content: message,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        resolve(true);
        console.log('YES');
      },
      onCancel() {
        resolve(false);
        console.log('NO');
      },
    });
  });
}
//START paymentGatewayDialog 
let pg = null;
function selectPG(e) {
  pg = e.target.value;
}
export const paymentGatewayDialog = () => {
  return new Promise((resolve, reject) => {
    confirm({
      title: 'Select payment Method',
      icon: <DollarOutlined />,
      content: (
        <div>
          <Divider />
          <Radio.Group onChange={selectPG}>
            <Space direction="vertical">
              <Radio value={'kpg'}>Credit card</Radio>
              <Radio value={'paypal'}>PayPal</Radio>
            </Space>
          </Radio.Group>
        </div>
      ),
      okText: 'OK',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        resolve(pg);
      },
      onCancel() {
        resolve(false);
      },
    });
  });
}
//END paymentGatewayDialog 

//START  inputTextDialog
let inputText = null;
function inputTextFan(e) {
  inputText = e.target.value;
}
export const inputTextDialog = (title, prefix) => {
  return new Promise((resolve, reject) => {
    confirm({
      title: title,
      icon: <CheckCircleOutlined />,
      content: (
        <div>
          <Input prefix={`${prefix}:`} onChange={inputTextFan} />
        </div>
      ),
      okText: 'OK',
      cancelText: 'Cancel',
      onOk() {
        if (inputText === '' || inputText === null) {
          resolve(null);
        } else {
          resolve(inputText);
        }

      },
      onCancel() {
        resolve(false);
      },
    });
  });
}
//END inputTextDialog


