import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import classes from './SearchBox.module.css';
class SearchBox extends Component {
    constructor(props) {
        super(props)
        this.state = {
            view: this.props.view,
            key: ''
        }
        this.onKeyUp = this.onKeyUp.bind(this);

    }
    componentDidMount() {
        this.setState({
            key: this.props.match.params.key
        })
    }

    handleInputChange = event => {
        const query = event.target.value;
        this.setState({
            key: query
        }, this.filterArray)
    };

    onKeyUp(event) {
        if (event.charCode === 13 && this.state.key) {
            this.props.history.push(`/page/search/${this.state.key}`);
        }
    }
    onClick() {
        if (this.state.key) {
            this.props.history.push(`/page/search/${this.state.key}`);
        }
    }

    render() {

        return (
            <div>
                {
                    this.state?.view === 'pc' ? (
                        <div className="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-5 my-2 my-md-0 osahan-navbar-search">
                            <div className="input-group">
                                <input
                                    onKeyPress={this.onKeyUp.bind(this)}
                                    value={this.state.key}
                                    onChange={this.handleInputChange}
                                    type="text" className="form-control" placeholder="Search for...">
                                </input>
                                <button onClick={this.onClick.bind(this)} className="btn btn-light" type="button">
                                    <i className="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="top-mobile-search">
                            <div className="row">
                                <div className="col-md-12">
                                    <form className="mobile-search">
                                        <div className="input-group">
                                            <input value={this.state.key} onChange={this.handleInputChange} type="text" placeholder="Search for..." className="form-control" />
                                            <NavLink to={"/page/search/" + this.state?.key} className="input-group-append">
                                                <button type="button" className={["btn btn-dark", classes.btnDark].join(" ")}><i className="fas fa-search"></i></button>
                                            </NavLink>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>

        )
    }
}




export default withRouter(SearchBox)
