import * as actionTypes from '../actions/auth/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  user: null,
  success: false,
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAILED:
      return authFailed(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    default:
      return state;
  }
};

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const authSuccess = (state, action) => {
  const userObj = {
    user: action.user,
    success: true,
    loading: false,
    error: null
  }
  return updateObject(state, userObj);
};

const authFailed = (state, action) => {
  return updateObject(state, {
    error: action.error?.data,
    loading: false,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, initialState);
};

export default reducer;