import React, { Component } from 'react';
import { Form, Input, Modal } from 'antd';
import api from '../../../../tools/api';
import InfoButton from '../../../Buttons/InfoButton/InfoButton';
import classes from './GetCardInfo.module.css';

class GetCardInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            video: null,
            loading: false,
            cardNumber: props.cardNumber
        }
    }

    onSearch = (value) => {
        const count = value.length;
        if (count <= 11) {
            Modal.error({
                title: `Error`,
                content: (
                    <div>
                        <p class="text-left">
                            Minimum 12 digit needed
                        </p>
                    </div>
                ),
                onOk() { },
            });
            return;
        }
        this.setState({ loading: true })
        const postCard = {
            "card": value
        }
        api.post(`card/check`, postCard)
            .then((res) => {
                this.setState({ loading: false, cardNumber: value })
                const { data, status } = res.data;
                if (status === 200) {
                    this.props.callback(data);
                } else {
                    //errorDialog('Error', 'Invalid card number')
                }
            })
            .catch((err) => {
                this.setState({ loading: false })
                //errorDialog('Error', 'Invalid card number')
            })
    };

    render() {
        const { Search } = Input;
        return (
            <div className={['d-flex flex-column justify-content-center', classes.container].join(' ')}>
                <Form
                    name="basic"
                    initialValues={{
                        cardNumber: this.state.cardNumber
                    }}>
                    <Form.Item
                        name="cardNumber"
                        rules={[
                            {
                                required: true,

                                message: 'Minimum 12 digit needed',
                            },
                        ]}>
                        <Search
                            name="cardNumber"
                            type="number"
                            pattern="^-?[0-9]\d*\.?\d*$"
                            enterButton="Submit"
                            size="large"
                            loading={this.state.loading}
                            onSearch={this.onSearch}
                            value={this.state.cardNumber}
                            suffix={
                                <InfoButton title="Pin number" content="<p style='text-align:justify;'>
                                Pin number is the payment card number for call TV construction with 12 digits. This Call TV card is, It is sold at shopping mall (http://calltvcard.com) or at resellers and distributors nationwide.<br> <br>
                                <a href='http://calltvcard.com'>Go to shopping mall</a>
                                </p>" />
                            }
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default GetCardInfo;