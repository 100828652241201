import React, { Component } from 'react';
import api from '../../tools/api';
import Title from '../../components/Title/Title';
import AgentCard from '../../components/Cards/AgentCard/AgentCard';
import Spinner from '../../components/Spinner/Spinner';
import { Nav, Sidebar, Footer } from '../../components/common'
class AgentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            from: 0,
            limit: 4000,
            loading: false
        };
    }

    componentDidMount() {
        this.GetCategories();
    }

    GetCategories() {
        this.setState({
            loading: true
        });
        api.get(`card/GetAgent`)
            .then((res) => {
                const itemList = res.data.data
                let items = this.state.items
                itemList.forEach(item => {
                    items.push(item);
                });

                this.setState({
                    categories: items
                })
                const from = this.state.from + 1;
                const limit = this.state.limit;
                this.setState({
                    from: from + limit,
                    loading: false
                });
            })
            .catch((err) => {
                this.setState({
                    loading: false
                });
                console.log(err);
            });
    }

    render() {

        return (
            <div>
                <Nav></Nav>
                <Sidebar></Sidebar>
                <div id="wrapper">
                    <div id="content-wrapper">
                        <div className="container-fluid pb-0">
                            <div className="video-block section-padding">
                                <Title title={"Community"} showLine={false}></Title>
                                <div className="row">
                                    {this.state.items.map((item) =>
                                        <AgentCard key={item.id + Math.random()} agent={item}></AgentCard>
                                    )}
                                </div>
                                {this.state.loading ? (<Spinner size={50}></Spinner>) : ("")}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>

        );
    }
}


export default AgentPage;