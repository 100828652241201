import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Player from '../../components/Player/Player';
import VideoTitle from '../../components/VideoComponents/VideoTitle/VideoTitle';
import ChannelName from './components/ChannelName/ChannelName';
import VideoAbout from './components/VideoAbout/VideoAbout';
import { Alert } from 'antd';
import CategoryVideosList from '../../components/VideoComponents/CategoryVideosList/CategoryVideosList';
import { Nav, Sidebar } from '../../components/common'
class PlayerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            video: this.props.video,
            isDialing: false,
            dialNumber: null
        };
    }

    componentDidMount() {
        const dialNumber = this.props.match.params.dial;
        this.props.onPlay(dialNumber);

    }

    componentDidUpdate(prevProps) {
        const dialNumber = this.props.match.params.dial;
        if (dialNumber !== prevProps.match.params.dial) {
            this.props.onPlay(dialNumber);
        }
    }


    render() {
        if (this.props.video?.IsExpired) {
            return (

                <div>
                    <Nav></Nav>
                    <Sidebar></Sidebar>
                    <div id="wrapper">
                        <div id="content-wrapper">
                            <div className="container-fluid pb-0">
                                <div className="video-block-right-list section-padding">
                                    <div className="row mb-4">
                                        <Alert showIcon
                                            message="Time expired"
                                            description="If you are the video owner. Please active it and extend time. Thank you"
                                            type="warning"
                                        />
                                        <div className="col-md-4">
                                            {this.props.video ? (
                                                <div className="video-slider-right-list">
                                                    <CategoryVideosList video={this.props.video}></CategoryVideosList>
                                                    {/* <VideoCardList></VideoCardList> */}
                                                </div>
                                            ) : (" ")
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="video-block section-padding">
                                    <div className="row">
                                        <div className="col-md-8">
                                            {this.props.video && (
                                                <div className="single-video-left">
                                                    <VideoTitle video={this.props.video} ></VideoTitle>
                                                    <ChannelName video={this.props.video}></ChannelName>
                                                    <VideoAbout video={this.props.video} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <Nav></Nav>
                <Sidebar></Sidebar>
                <div id="wrapper">
                    <div id="content-wrapper">
                        <div className="container-fluid pb-0">
                            <div className="video-block-right-list section-padding">
                                <div className="row mb-4">
                                    <Player></Player>
                                    <div className="col-md-4">
                                        {this.props.video ? (
                                            <div className="video-slider-right-list">
                                                <CategoryVideosList video={this.props.video}></CategoryVideosList>
                                                {/* <VideoCardList></VideoCardList> */}
                                            </div>
                                        ) : (" ")
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="video-block section-padding">
                                <div className="row">
                                    <div className="col-md-8">
                                        {this.props.video && (
                                            <div className="single-video-left">
                                                <VideoTitle video={this.props.video} ></VideoTitle>
                                                <ChannelName video={this.props.video}></ChannelName>
                                                <VideoAbout video={this.props.video} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        );
    }
}

const mapStateToProps = (state) => {
    // console.log(state.dial.video);
    return {
        video: state.dial.video,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPlay: (DialNumber) => dispatch(actions.DialNumber(DialNumber)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerPage);