import React from 'react'
import BarButton from '../Buttons/BarButton/BarButton';
import Logo from '../Logo/Logo';
import SearchBox from '../SearchBox/SearchBox';
import Avatar from '../Avatar/Avatar'
import UploadButton from '../Buttons/UploadVideoButton/UploadVideoButton'
import LoginButton from '../Buttons/LoginButton/LoginButton';
import classes from './Nav.module.css';
import { Space } from 'antd';
import { Button } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import VoiceSearchButton from '../Buttons/VoiceSearchButton/VoiceSearchButton';
import SearchButton from '../Buttons/SearchButton/SearchButton';
const Nav = () => {
   return (
      <nav className={["d-flex justify-content-between align-items-center navbar navbar-expand navbar-light bg-white static-top osahan-nav sticky-top", classes.nav].join(" ")}>
         <div>
            <BarButton></BarButton>
            <Logo></Logo>
         </div>
         <div className="d-flex justify-content-center">
            <SearchBox view={'pc'}></SearchBox>
         </div>
         <div className="d-flex justify-content-end">
            <Space size={[8, 16]} wrap>
               <NavLink to="/page/videos">
                  <Button icon={<PlayCircleOutlined />} />
               </NavLink>
               <SearchButton></SearchButton>
               <VoiceSearchButton></VoiceSearchButton>
               <UploadButton></UploadButton>
               <LoginButton></LoginButton>
               <Avatar></Avatar>
            </Space>

         </div>
      </nav>
   )
}

export default Nav