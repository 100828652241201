import React, { Component } from 'react'
import Title from '../../components/Title/Title';
import VideoCard from '../../components/Cards/VideoCard/VideoCard';
import Spinner from '../../components/Spinner/Spinner';
import api from '../../tools/api';
import { Nav, Sidebar, Footer } from '../../components/common'
class LiveVideosPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: [],
            from: 0,
            limit: 16,
            loading: false
        };
        this.getNewVideos = this.getNewVideos.bind(this);
    }
    componentDidMount() {
        this.getNewVideos();
    }
    getNewVideos() {
        this.setState({
            loading: true
        });
        api.get(`video/LiveVideos/${this.state.from}/${this.state.limit}`)
            .then((res) => {
                const newVideos = res.data.data
                let videoList = this.state.videos
                newVideos.forEach(video => {
                    videoList.push(video);
                });

                this.setState({
                    videos: videoList
                })
                const from = this.state.from + 1;
                const limit = this.state.limit;
                this.setState({
                    from: from + limit,
                    loading: false
                });

            })
            .catch((err) => {
                this.setState({
                    loading: false
                });
                console.log(err);
            });
    }

    render() {
        return (
            <div>
                <Nav></Nav>
                <Sidebar></Sidebar>
                <div id="wrapper">
                    <div id="content-wrapper">
                        <div className="container-fluid pb-0">
                            {this.state.videos.length > 0 ? (
                                <div className="video-block section-padding">
                                    <div className="row">
                                        <Title title={"Live videos"}></Title>
                                        {this.state.videos.map((video) =>
                                            <VideoCard key={video.id + Math.random()} video={video}></VideoCard>
                                        )}
                                    </div>
                                    <button onClick={this.getNewVideos} type="button" className="btn btn-primary">Load more...</button>
                                </div>
                            ) : ("")
                            }
                            <div>
                                {this.state.loading ? (
                                    <Spinner size={50}></Spinner>
                                ) : ('')}
                            </div>

                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>

        )
    }
}
export default LiveVideosPage
