
import React, { Component } from 'react';
import { Nav, Sidebar, Footer } from '../../components/common'
import Title from '../../components/Title/Title';
import api from '../../tools/api';
class PageReader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: {},
        };

    }
    componentDidMount() {
        this.GetPage();
    }

    GetPage() {
        this.setState({
            loading: true
        });
        api.get(`app/GetPage/${this.props.match.params.id}`)
            .then((res) => {
                const page = res.data.data
                this.setState({
                    page: page,
                    loading: false
                });
            })
            .catch((err) => {
                this.setState({
                    loading: false
                });
                console.log(err);
            });
    }
    render() {
        return (
            <div>
                <Nav></Nav>
                <Sidebar></Sidebar>
                <div id="wrapper">
                    <div id="content-wrapper">
                        <div className="container-fluid pb-0">
                            <Title title={this.state.page.title} showLine={false}></Title>
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: this.state.page.text }} />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export default PageReader;