import React from 'react';
import TopSlider from '../../components/Sliders/TopSlider/TopSlider'
import NewVideos from '../../components/VideoComponents/NewVideos/NewVideos';
import PopularChannels from '../../components/ChannelComponents/PopularChannels/PopularChannels';

import { Nav, Sidebar, Footer } from '../../components/common'

const HomePage = () => {

   return (
      <div>
         <Nav></Nav>
         <Sidebar></Sidebar>
         <div id="wrapper">
            <div id="content-wrapper">
               <div className="container-fluid pb-0">
                  <TopSlider></TopSlider>
                  <NewVideos></NewVideos>
                  <PopularChannels></PopularChannels>
               </div>
            </div>
         </div>
         <Footer></Footer>
      </div>

   )
}

export default HomePage
