import React, { Component } from 'react';
import Title from '../../components/Title/Title';
import { Button, Typography } from 'antd';
import { Nav, Sidebar, Footer } from '../../components/common';
const { Text } = Typography;
class IamportReturnPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imp_uid: null,
            imp_success: null,
            error_msg: null
        }
    }
    componentDidMount() {
        const imp_success = true;//(new URL(window.location.href)).searchParams.get('imp_success');
        const error_msg = (new URL(window.location.href)).searchParams.get('error_msg');
        this.setState({
            imp_success: imp_success,
            error_msg: error_msg,
        });
    }
    goTo = () => {
        window.location.href = '/';
    }



    render() {

        return (
            <div>
                <Nav></Nav>
                <Sidebar></Sidebar>
                <div id="wrapper">
                    <div id="content-wrapper">
                        <div className="container-fluid pb-0">
                            <div className="video-block section-padding">
                                {/* <Title title={"Payment"} showLine={false}></Title> */}
                                {this.state.imp_success === true ? (
                                    <div className="d-flex flex-column align-items-center">
                                        <div>Successful</div>
                                        <div> CallTV Card PIN Number was sent to your email successfully. it will take 2~3 min later to your email.</div>
                                        <br></br>
                                        <Button onClick={this.goTo} type="primary" danger>Go to Home</Button>
                                    </div>
                                ) : (
                                    <p>Unable to make payment {this.state.error_msg}</p>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export default IamportReturnPage;