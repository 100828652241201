export const SetItem = (key, obj) => {
    localStorage.setItem(key, JSON.stringify(obj));
};

export const GetItem = (key) => {
    const obj = localStorage.getItem(key);
    return JSON.parse(obj);
};

export const RemoveItem = (key) => {
    localStorage.removeItem(key);
};
